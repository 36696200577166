import { COMPONENT_LOAD_ERROR } from "../../../constants";
import {
  EErrorType,
  type ErrorService,
  type IApiError,
} from "draivn-ui-domain";
import { useSystemStore } from "../../store";
import { ECommonMessages } from "../../../types";
import { DialogPlugin } from "draivn-ui-kit";
import ConfirmDialog from "../../../components/common/ConfirmDialog.vue";
import { injector } from "@/services";
import { I18N_SERVICE_INJECTION_KEY } from "@/constants";

export const componentLoadDisplayHandlerInit = (errorService: ErrorService) => {
  const i18n = injector.injectFromRoot(I18N_SERVICE_INJECTION_KEY);

  const componentLoadErrorDisplayHandler = (error: IApiError) => {
    const isComponentLoadError =
      (error.data[0] as Error).message === COMPONENT_LOAD_ERROR;
    if (isComponentLoadError) {
      const { t } = i18n.global;
      errorService.blockDisplay();
      const $systemStore = useSystemStore();
      $systemStore.stopHealthCheck();
      DialogPlugin.create({
        component: ConfirmDialog,
        componentProps: {
          title: t(ECommonMessages.RESOLVE_COMPONENT_ERROR_TITLE),
          message: t(ECommonMessages.RESOLVE_COMPONENT_ERROR_DESCRIPTION),
          icon: "traffic-cone",
          iconColor: "r400",
          iconBgColor: "r50",
          okBtn: {
            label: t(ECommonMessages.RESOLVE_COMPONENT_ERROR_BUTTON_TITLE),
          },
        },
      }).onOk(() => {
        window.location.reload();
      });
    }
  };

  errorService.registerErrorDisplay(EErrorType.LOCAL, {
    display: componentLoadErrorDisplayHandler,
  });
};
