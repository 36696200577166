export const ERRORS_MESSAGES = {
  MAX_SIZE: "File size exceeds maximum limit {maxSize} MB",
  VALID_URL: "Please enter a valid URL",
  ACCEPT_TYPES: "You can only upload following files: {fileTypes}",
  PARSING_FILE: "Failed to upload your file. Please, try again.",
  COMPANY_STEP: "Fail submitted step Insurance",
  TELEMATIC_STEP: "Fail submitted step Telematics",
  TRY_LATER:
    "You have exceeded the number of attempts. Try it in {minutes} minutes",
};
