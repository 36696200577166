export const COMMON_MESSAGES = {
  SUCCESS: "Success",
  OK: "OK",
  CHANGES_APPLIED: "Changes applied",
  COPIED: "Copied!",
  NOT_AVAILABLE: "N/A",
  NO_VALUE: "-",
  TABLE_DEFAULT_FILTER_ACTION_TITLE: "Filter",
  TABLE_DEFAULT_EXPORT_ACTION_TITLE: "Export",
  TABLE_DEFAULT_REFRESH_ACTION_TITLE: "Refresh",
  CONGRATULATION_MESSAGE_TITLE: "Congratulations!",
  VALIDATORS_REQUIRED_COMMON_MESSAGE: "Field is required",
  VALIDATORS_NAME_BUSY_COMMON_MESSAGE: "Name is busy",
  VALIDATORS_EMAIL_COMMON_EMPTY_MESSAGE: "Please enter the email",
  VALIDATORS_EMAIL_COMMON_MESSAGE: "Email is invalid",
  VALIDATORS_DATE_INVALID_COMMON_EMPTY_MESSAGE: "Please make a selection",
  VALIDATORS_DATE_INVALID_COMMON_MESSAGE: "Date is invalid",
  VALIDATORS_DATE_GREATER_THEN_INVALID_COMMON_MESSAGE:
    "Date must be greater then {date}",
  VALIDATORS_DATE_LESS_THEN_INVALID_COMMON_MESSAGE:
    "Date must be less then {date}",
  VALIDATION_EMAIL_EXIST_COMMON_TITLE: "Email is already in use",
  DELETE_ITEMS_COMMON_TITLE: "Delete confirmation",
  DELETE_ITEMS_DESCRIPTION_TITLE:
    "Are you sure that you want to delete {item}?",
  DELETE_COMMON_TITLE: "Delete",
  DATE_COMMON_PLACEHOLDER: "MM/DD/YYYY",
  CANCEL: "Cancel",
  SLASH_DELIMITER: "/",
  ERROR_INTERNET_TITLE: "Oops!",
  ERROR_INTERNET_MESSAGE: "It looks like you've lost your Internet connection.",
  ERROR_AUTH_TITLE: "Oops!",
  ERROR_AUTH_MESSAGE: "It looks like the session expired.",
  ERROR_DEFAULT_TITLE: "Oops!",
  ERROR_DEFAULT_MESSAGE: "Something went wrong.",
  RELOGIN_BUTTON_TITLE: "Login",
  ERROR_SERVER_TITLE: "Service temporarily unavailable.",
  ERROR_SERVER_MESSAGE: "Reconnecting shortly.",
  RESOLVE_COMPONENT_ERROR_TITLE: "Oops!",
  RESOLVE_COMPONENT_ERROR_DESCRIPTION:
    "App module loading failed. Please reload the page.",
  RESOLVE_COMPONENT_ERROR_BUTTON_TITLE: "Reload",
  ERROR_SERVER_BUTTON: "Reconnecting",
};
