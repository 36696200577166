import { validationtDisplayHandlerInit } from "./handlers";
import { ERROR_SERVICE_INJECTION_KEY } from "@/constants";
import { injector } from "@/services";

export default () => {
  const errorService = injector.injectFromRoot(ERROR_SERVICE_INJECTION_KEY);
  if (errorService) {
    validationtDisplayHandlerInit(errorService);
  }
};
