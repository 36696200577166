import { type AsyncComponentLoader, h, defineComponent } from "vue";
import { getAsyncComponent } from "./get-async-component";

export const getAsyncComponentWrapper = (loader: AsyncComponentLoader) => {
  const component = getAsyncComponent(loader);

  return defineComponent({
    functional: true,
    render() {
      return h(component);
    },
  });
};
