import type { IMapper } from "~/types";
import type { IArticle, IArticleResponseModel } from "../../types";

export const articleMapper: IMapper<IArticleResponseModel, IArticle> = (
  value
) => value;

export const articleListMapper: IMapper<IArticleResponseModel[], IArticle[]> = (
  value: IArticleResponseModel[]
) => value.map(articleMapper);
