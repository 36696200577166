import { getAsyncComponentWrapper } from "@/modules/app";
import {
  HELP_CENTER_ROUTE_NAME,
  HELP_CENTER_ROOT_ROUTE_NAME,
  HELP_CENTER_ARTICLE_ROUTE_NAME,
  HELP_CENTER_ROOT_ROUTE_PATH,
} from "../../constants";
import { EMessagesKeys, IBreadcrumb } from "../../types";
import { useMenuItemsFeature } from "../../composables";
import { injector } from "@/services";
import { I18N_SERVICE_INJECTION_KEY } from "@/constants";
import { RouteLocationNormalizedLoaded } from "vue-router";

const MAIN_BREADCRUMBS: IBreadcrumb = () => {
  const i18n = injector.injectFromRoot(I18N_SERVICE_INJECTION_KEY);
  const { t } = i18n.global;
  return [
    {
      name: t(EMessagesKeys.HELP_CENTER_TITLE),
      to: { name: HELP_CENTER_ROUTE_NAME },
    },
  ];
};

const HELP_START_BREADCRUMBS: IBreadcrumb = (
  route: RouteLocationNormalizedLoaded
) => {
  return MAIN_BREADCRUMBS(route);
};

const HELP_ARTICLE_BREADCRUMBS: IBreadcrumb = (
  route: RouteLocationNormalizedLoaded
) => {
  const { breadcrubsItems } = useMenuItemsFeature();
  const breadcrumbs = [];
  const groupBreadcrumb = breadcrubsItems.value[0];
  const articleBreadcrumb =
    breadcrubsItems.value[breadcrubsItems.value.length - 1];
  if (groupBreadcrumb && articleBreadcrumb) {
    breadcrumbs.push(groupBreadcrumb);
    breadcrumbs.push(articleBreadcrumb);
  }
  return [...MAIN_BREADCRUMBS(route), ...breadcrumbs];
};

export const ROUTE = {
  path: HELP_CENTER_ROOT_ROUTE_PATH,
  name: HELP_CENTER_ROOT_ROUTE_NAME,
  component: getAsyncComponentWrapper(
    () => import("../../components/MainComponent.vue")
  ),
  redirect: { name: HELP_CENTER_ROUTE_NAME },
  children: [
    {
      path: "",
      name: HELP_CENTER_ROUTE_NAME,
      component: getAsyncComponentWrapper(
        () => import("../../components/pages/StartPage.vue")
      ),
      meta: {
        breadcrumbs: HELP_START_BREADCRUMBS,
      },
    },
    {
      path: ":pathMatch(.*)*",
      name: HELP_CENTER_ARTICLE_ROUTE_NAME,
      component: getAsyncComponentWrapper(
        () => import("../../components/pages/ArticlePage.vue")
      ),
      meta: {
        breadcrumbs: HELP_ARTICLE_BREADCRUMBS,
      },
    },
  ],
};
