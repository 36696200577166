import { injector } from "@/services";
import { API_SERVICE_INJECTION_KEY } from "@/constants";
import {
  IPortalArticle,
  IPortalArticleResponseModel,
} from "@/modules/onboarding";

export const getPortalArticle = async (group: string, alias: string) => {
  const apiService = injector.injectFromRoot(API_SERVICE_INJECTION_KEY);

  let result: IPortalArticle | null = null;
  // TODO: revert after website published
  // const response = await apiService.get<IPortalArticleResponseModel[]>(
  //   `portal/menu/${group}/article/${alias}`
  // );
  const response = await apiService.get<IPortalArticleResponseModel[]>(
    `help/menu/${group}/article/${alias}`
  );
  if (apiService.isRequestSuccess(response) && response.data) {
    result = response.data[0];
  }
  return result;
};
