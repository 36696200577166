import type { IMapper } from "~/types";
import type { II18NMessage, II18NMessageResponseModel } from "../../types";

export const i18nMessageMapper: IMapper<
  II18NMessageResponseModel,
  II18NMessage
> = (value) => value;

export const i18nMessageListMapper: IMapper<
  II18NMessageResponseModel[],
  II18NMessage[]
> = (value: II18NMessageResponseModel[]) => value.map(i18nMessageMapper);
