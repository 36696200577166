import { injector } from "@/services";
import {
  type ErrorService,
  EErrorType,
  type IApiError,
} from "draivn-ui-domain";
import { ECommonMessages } from "../../../types";
import { NotifyPlugin } from "draivn-ui-kit";
import { I18N_SERVICE_INJECTION_KEY } from "@/constants";

export const defaultDisplayHandlerInit = (errorService: ErrorService) => {
  const i18n = injector.injectFromRoot(I18N_SERVICE_INJECTION_KEY);
  const defaultDisplayHandler = (error: IApiError) => {
    console.error(error);
    const { t } = i18n.global;
    errorService.blockDisplay();
    NotifyPlugin.create({
      message: t(ECommonMessages.ERROR_DEFAULT_TITLE),
      caption: t(ECommonMessages.ERROR_DEFAULT_MESSAGE),
      type: "draivn-toast-warning",
      icon: "error_outline",
      onDismiss() {
        errorService.unblockDisplay();
      },
    });
  };

  errorService.registerErrorDisplay(EErrorType.UNKNOWN, {
    display: defaultDisplayHandler,
  });
};
