import type { App } from "vue";
import { INJECTOR_SERVICE_INJECTION_KEY } from "@/constants";
import { Injector } from "./injector";

export const injector = new Injector();

export default (app: App) => {
  app.provide(INJECTOR_SERVICE_INJECTION_KEY, injector);
  return injector;
};
