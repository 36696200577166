export enum ECommonMessages {
  SUCCESS = "COMMON.SUCCESS",
  CHANGES_APPLIED = "COMMON.CHANGES_APPLIED",
  COPIED = "COMMON.COPIED",
  NOT_AVAILABLE = "COMMON.NOT_AVAILABLE",
  NO_VALUE = "COMMON.NO_VALUE",
  TABLE_DEFAULT_FILTER_ACTION_TITLE = "COMMON.TABLE_DEFAULT_FILTER_ACTION_TITLE",
  TABLE_DEFAULT_EXPORT_ACTION_TITLE = "COMMON.TABLE_DEFAULT_EXPORT_ACTION_TITLE",
  TABLE_DEFAULT_REFRESH_ACTION_TITLE = "COMMON.TABLE_DEFAULT_REFRESH_ACTION_TITLE",
  CONGRATULATION_MESSAGE_TITLE = "COMMON.CONGRATULATION_MESSAGE_TITLE",
  VALIDATORS_REQUIRED_COMMON_MESSAGE = "COMMON.VALIDATORS_REQUIRED_COMMON_MESSAGE",
  VALIDATORS_EMAIL_COMMON_EMPTY_MESSAGE = "COMMON.VALIDATORS_EMAIL_COMMON_EMPTY_MESSAGE",
  VALIDATORS_EMAIL_COMMON_MESSAGE = "COMMON.VALIDATORS_EMAIL_COMMON_MESSAGE",
  VALIDATORS_DATE_INVALID_COMMON_EMPTY_MESSAGE = "COMMON.VALIDATORS_DATE_INVALID_COMMON_EMPTY_MESSAGE",
  VALIDATORS_DATE_INVALID_COMMON_MESSAGE = "COMMON.VALIDATORS_DATE_INVALID_COMMON_MESSAGE",
  VALIDATORS_DATE_GREATER_THEN_INVALID_COMMON_MESSAGE = "COMMON.VALIDATORS_DATE_GREATER_THEN_INVALID_COMMON_MESSAGE",
  VALIDATORS_DATE_LESS_THEN_INVALID_COMMON_MESSAGE = "COMMON.VALIDATORS_DATE_LESS_THEN_INVALID_COMMON_MESSAGE",
  VALIDATION_EMAIL_EXIST_COMMON_TITLE = "COMMON.VALIDATION_EMAIL_EXIST_COMMON_TITLE",
  DELETE_ITEMS_COMMON_TITLE = "COMMON.DELETE_ITEMS_COMMON_TITLE",
  DELETE_ITEMS_DESCRIPTION_TITLE = "COMMON.DELETE_ITEMS_DESCRIPTION_TITLE",
  DELETE_COMMON_TITLE = "COMMON.DELETE_COMMON_TITLE",
  DATE_COMMON_PLACEHOLDER = "COMMON.DATE_COMMON_PLACEHOLDER",
  CANCEL = "COMMON.CANCEL",
  SLASH_DELIMITER = "COMMON.SLASH_DELIMITER",
  ERROR_INTERNET_TITLE = "COMMON.ERROR_INTERNET_TITLE",
  ERROR_INTERNET_MESSAGE = "COMMON.ERROR_INTERNET_MESSAGE",
  ERROR_AUTH_TITLE = "COMMON.ERROR_AUTH_TITLE",
  ERROR_AUTH_MESSAGE = "COMMON.ERROR_AUTH_MESSAGE",
  RELOGIN_BUTTON_TITLE = "COMMON.RELOGIN_BUTTON_TITLE",
  ERROR_SERVER_TITLE = "COMMON.ERROR_SERVER_TITLE",
  ERROR_SERVER_MESSAGE = "COMMON.ERROR_SERVER_MESSAGE",
  OK = "COMMON.OK",
  ERROR_DEFAULT_TITLE = "COMMON.ERROR_DEFAULT_TITLE",
  ERROR_DEFAULT_MESSAGE = "COMMON.ERROR_DEFAULT_MESSAGE",
  RESOLVE_COMPONENT_ERROR_TITLE = "COMMON.RESOLVE_COMPONENT_ERROR_TITLE",
  RESOLVE_COMPONENT_ERROR_DESCRIPTION = "COMMON.RESOLVE_COMPONENT_ERROR_DESCRIPTION",
  RESOLVE_COMPONENT_ERROR_BUTTON_TITLE = "COMMON.RESOLVE_COMPONENT_ERROR_BUTTON_TITLE",
  ERROR_SERVER_BUTTON = "COMMON.ERROR_SERVER_BUTTON",
}
