import i18nInstall from "./i18n";
import storeInstall from "./store";
import errorInstall from "./error";
import routerInstall from "./router";
import configRenderInstall from "./config-render";
import { IModuleParams } from "~/types";
import { injector } from "@/services";
import { ROUTER_SERVICE_INJECTION_KEY } from "@/constants";
export * from "./api";

export * from "./store";

export default async (params: IModuleParams | void) => {
  const { routerConfig } = params ?? {};
  if (routerConfig) {
    await routerInstall(routerConfig);
  }
  const stores = storeInstall();
  i18nInstall();
  errorInstall();

  const $onboardingStore = stores.useOnboardingStore();
  const $router = injector.injectFromRoot(ROUTER_SERVICE_INJECTION_KEY);
  const token =
    ($router.currentRoute.value.query.token as string) ||
    $onboardingStore.token;
  $onboardingStore.setToken(token);
  await $onboardingStore.initSession();

  await configRenderInstall();

  return {
    stores,
  };
};
