import { useUIKit } from "draivn-ui-kit";
import SupportDialogVue from "../components/blocks/SupportDialog.vue";
import type { ISupportRequest } from "../types";
import { api } from "../services";
import { useHelpCenterStore } from "../services/store";
import { storeToRefs } from "pinia";

export const useShowSupportDialogFeature = () => {
  const $uiKit = useUIKit();
  const $helpCenterStore = useHelpCenterStore();
  const { supportInfo } = storeToRefs($helpCenterStore);

  const onSupportAsk = async (model: ISupportRequest) => {
    const resp = await api.postSupportRequest(model);
    return resp;
  };

  const showSupportDialog = () => {
    $uiKit
      .dialog({
        component: SupportDialogVue,
        componentProps: {
          value: supportInfo.value,
        },
      })
      .onOk((model: ISupportRequest) => {
        onSupportAsk(model);
      });
  };

  return {
    showSupportDialog,
  };
};
