import type { RouteRecordRaw } from "vue-router";
import { getAsyncComponentWrapper } from "@/modules/app";
import { router } from "@/services/router";
import {
  ROUTE_ONBOARDING_MAIN_NAME,
  ROUTE_WELCOME_NAME,
  ROUTE_CONGRATS_NAME,
  ROUTE_ONBOARDING_NAME,
} from "../../constants";

const onboardingRoute: RouteRecordRaw = {
  path: "",
  name: ROUTE_ONBOARDING_MAIN_NAME,
  component: getAsyncComponentWrapper(
    () => import("../../components/MainComponent.vue")
  ),
  children: [
    {
      path: "welcome",
      name: ROUTE_WELCOME_NAME,
      component: getAsyncComponentWrapper(
        () => import("../../components/pages/WelcomePage.vue")
      ),
    },
    {
      path: "onboarding",
      name: ROUTE_ONBOARDING_NAME,
      component: getAsyncComponentWrapper(
        () => import("../../components/pages/OnboardingPage.vue")
      ),
    },
    {
      path: "congrats",
      name: ROUTE_CONGRATS_NAME,
      component: getAsyncComponentWrapper(
        () => import("../../components/pages/CongratsPage.vue")
      ),
    },
  ],
};

export default async ({ mountRouteName }: { mountRouteName: string }) => {
  const mainRoute = router
    .getRoutes()
    .find((route) => route.name === mountRouteName);
  if (mainRoute) {
    const newMainRoute = { ...mainRoute };
    newMainRoute.children = [...newMainRoute.children, onboardingRoute];
    router.removeRoute(mountRouteName);
    router.addRoute(newMainRoute);
  }
  await router.replace(router.currentRoute.value.fullPath);
};
