import type { RouteRecordRaw } from "vue-router";
import { getAsyncComponentWrapper } from "../../composables";
import { router } from "@/services/router";
import { ROUTE_MAIN_NAME } from "../../constants";

const routes: RouteRecordRaw[] = [
  {
    path: "/",
    name: ROUTE_MAIN_NAME,
    component: getAsyncComponentWrapper(
      () => import("@/modules/app/components/MainComponent.vue")
    ),
  },
  {
    path: "/:catchAll(.*)*",
    component: getAsyncComponentWrapper(
      () => import("@/modules/app/components/pages/ErrorNotFoundPage.vue")
    ),
  },
];

export default async () => {
  routes.forEach((route) => {
    router.addRoute(route);
  });
};
