import type { systemStoreInstall } from "../services/store/modules";
import type {
  StoreDefinition,
  _ExtractActionsFromSetupStore,
  _ExtractGettersFromSetupStore,
  _ExtractStateFromSetupStore,
} from "pinia";
import type { InjectionKey } from "vue";

type SS = ReturnType<typeof systemStoreInstall>;
export const SYSTEM_STORE_INJECTION_KEY: InjectionKey<
  StoreDefinition<
    "system",
    _ExtractStateFromSetupStore<SS>,
    _ExtractGettersFromSetupStore<SS>,
    _ExtractActionsFromSetupStore<SS>
  >
> = Symbol("SYSTEM_STORE");
