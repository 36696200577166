import { STORE_SERVICE_INJECTION_KEY } from "@/constants";
import { createPinia } from "pinia";
import type { App } from "vue";
import { storagePiniaPlugin } from "./plugins";
import { injector } from "@/services/injector";

export const store = createPinia();

export default (app: App) => {
  store.use(storagePiniaPlugin);
  app.use(store);
  injector.provide(STORE_SERVICE_INJECTION_KEY, store);
  app.provide(STORE_SERVICE_INJECTION_KEY, store);
  return store;
};
