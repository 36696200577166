import { ref, type Ref } from "vue";
import type {
  IAppError,
  IAppInfo,
  ISystemMessage,
  TSystemMessageType,
} from "../../../../../types";

const createMessages = (
  type: TSystemMessageType,
  messages: IAppInfo | IAppInfo[]
): ISystemMessage[] => {
  let result: ISystemMessage[] = [];
  if (messages) {
    if (Array.isArray(messages)) {
      result = messages.map((data: IAppInfo) => ({ type, ...data }));
    } else {
      result = [{ type, ...messages }];
    }
  }
  return result;
};

export const useSystemNotificationsFeature = () => {
  const systemNotifications: Ref<ISystemMessage[]> = ref([]);

  const addInfoSystemNotification = (infos: IAppInfo[]) => {
    systemNotifications.value.push(...createMessages("info", infos));
  };

  const addErrorSystemNotification = (errors: IAppError[]) => {
    systemNotifications.value.push(...createMessages("error", errors));
  };

  return {
    systemNotifications,
    addInfoSystemNotification,
    addErrorSystemNotification,
  };
};
