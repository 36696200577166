import { IModuleParams } from "~/types";
import servicesInstall from "./services";

export { useOnboardingStore } from "./services";

export { default as MainComponent } from "./components/MainComponent.vue";

export * from "./types";

export default async (params: IModuleParams | void) => {
  await servicesInstall(params);
};
