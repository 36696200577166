import type { IMapper } from "~/types";
import type {
  IOnboardingSession,
  IOnboardingSessionResponseModel,
} from "../../types";

export const onboardingSessionMapper: IMapper<
  IOnboardingSessionResponseModel,
  IOnboardingSession
> = (value) => ({ session_id: value.session_id });
