import { EVENT_BUS_SERVICE_INJECTION_KEY } from "@/constants";
import { injector } from "@/services";
import { openURL } from "draivn-ui-kit";
import { EEvents } from "~/types";

export const useHelpLinkFeature = () => {
  const isLinkExternal = (link: string) => {
    return !link.startsWith("help");
  };
  const onExternalLinkClick = (link: string) => {
    openURL(link);
  };
  const onHelpLinkClick = (link: string) => {
    const eventBussService = injector.injectFromRoot(
      EVENT_BUS_SERVICE_INJECTION_KEY
    );
    eventBussService.emit(EEvents.ON_ARTICLE_LINK_CHANGE, link);
  };
  const onLinkClick = (link: string) => {
    if (isLinkExternal(link)) {
      onExternalLinkClick(link);
    } else {
      onHelpLinkClick(link);
    }
  };
  return {
    isLinkExternal,
    onHelpLinkClick,
    onExternalLinkClick,
    onLinkClick,
  };
};
