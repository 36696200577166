import { helpInfo } from "./mock";
import type {
  IArticle,
  IArticleResponseModel,
  ICollectionItem,
  ICollectionItemResponseModel,
  IDemoRequest,
  ISupportInfo,
  ISupportInfoResponseModel,
  IMenuItem,
  IMenuItemResponseModel,
  IOverview,
  IOverviewResponseModel,
  ISupportRequest,
} from "../../types";
import { API_SERVICE_INJECTION_KEY } from "@/constants";
import { injector } from "@/services";
import {
  articleListMapper,
  articleMapper,
  collectionItemListMapper,
  helpInfoMapper,
  menuItemMapper,
  overviewMapper,
} from "../mapping";
import { IRequestConfig } from "draivn-ui-domain";

export const getApi = (BASE_URL: string) => {
  const getOverview = async () => {
    const apiService = injector.injectFromRoot(API_SERVICE_INJECTION_KEY);
    let result: IOverview | null = null;
    const response = await apiService.get<IOverviewResponseModel[]>(
      `${BASE_URL}/overview"`
    );
    if (apiService.isRequestSuccess(response) && response.data) {
      const data = response.data[0];
      result = overviewMapper(data);
    }
    return result;
  };

  const getMenu = async (group: string) => {
    const apiService = injector.injectFromRoot(API_SERVICE_INJECTION_KEY);
    let result: IMenuItem | null = null;
    const response = await apiService.get<IMenuItemResponseModel[]>(
      `${BASE_URL}/menu/${group}`
    );
    if (apiService.isRequestSuccess(response) && response.data) {
      const data = response.data[0];
      result = menuItemMapper(data);
    }
    return result;
  };

  const getArticle = async (
    group: string,
    alias: string,
    config?: IRequestConfig
  ) => {
    const apiService = injector.injectFromRoot(API_SERVICE_INJECTION_KEY);
    let result: IArticle | null = null;
    const response = await apiService.get<IArticleResponseModel[]>(
      `${BASE_URL}/menu/${group}/article/${alias}`,
      undefined,
      config
    );
    if (apiService.isRequestSuccess(response) && response.data) {
      const data = response.data[0];
      result = articleMapper(data);
    }
    return result;
  };

  const getCollection = async (tags?: string[]) => {
    const apiService = injector.injectFromRoot(API_SERVICE_INJECTION_KEY);
    let result: ICollectionItem[] | null = null;
    let params;
    if (tags) {
      params = { tags: tags.join(",") };
    }
    const response = await apiService.get<ICollectionItemResponseModel[]>(
      `${BASE_URL}/records`,
      params
    );
    if (apiService.isRequestSuccess(response) && response.data) {
      const data = response.data;
      result = collectionItemListMapper(data);
    }
    return result;
  };

  const getSearch = async (query: string) => {
    const apiService = injector.injectFromRoot(API_SERVICE_INJECTION_KEY);
    let result: IArticle[] = [];
    const params = { query };
    const response = await apiService.get<IArticleResponseModel[]>(
      `${BASE_URL}/search`,
      params
    );
    if (apiService.isRequestSuccess(response) && response.data) {
      const data = response.data;
      result = articleListMapper(data);
    }
    return result;
  };

  const postDemoRequest = async (data: IDemoRequest) => {
    const apiService = injector.injectFromRoot(API_SERVICE_INJECTION_KEY);
  };

  const postSupportRequest = async (data: ISupportRequest) => {
    const apiService = injector.injectFromRoot(API_SERVICE_INJECTION_KEY);
  };

  const getSupportInfo = async () => {
    const apiService = injector.injectFromRoot(API_SERVICE_INJECTION_KEY);
    const result: ISupportInfo = helpInfo[0];
    // let result: ISupportInfo | null = null
    // const response = await apiService.get<ISupportInfoResponseModel[]>(`${BASE_URL}/support-info`)
    // if (apiService.isRequestSuccess(response) && response.data) {
    //   const data = helpInfo // response.data
    //   result = helpInfoMapper(data[0])
    // }
    return result;
  };

  return {
    getOverview,
    getMenu,
    getArticle,
    getCollection,
    getSearch,
    postDemoRequest,
    postSupportRequest,
    getSupportInfo,
  };
};
