import type { IMapper } from "~/types";
import type { IOverview, IOverviewResponseModel } from "../../types";

export const overviewMapper: IMapper<IOverviewResponseModel, IOverview> = (
  value
) => value;

export const overviewListMapper: IMapper<
  IOverviewResponseModel[],
  IOverview[]
> = (value: IOverviewResponseModel[]) => value.map(overviewMapper);
