import type { App } from "vue";
import { AclService } from "./acl-service";
import { ACL_SERVICE_INJECTION_KEY } from "@/constants";
import { injector } from "@/services/injector";

export const aclService = new AclService({});

export default async (app: App) => {
  await aclService.build();
  injector.provide(ACL_SERVICE_INJECTION_KEY, aclService);
  app.provide(ACL_SERVICE_INJECTION_KEY, aclService);
  return aclService;
};
