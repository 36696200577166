import { ECommonMessages } from "../../../types";
import { DialogPlugin } from "draivn-ui-kit";
import ConfirmDialog from "../../../components/common/ConfirmDialog.vue";
import { EErrorType, type ErrorService } from "draivn-ui-domain";
import { I18N_SERVICE_INJECTION_KEY } from "@/constants";
import { useSystemStore } from "../../store";
import { injector } from "@/services";

export const authErrorDisplayHandlerInit = (errorService: ErrorService) => {
  const i18n = injector.injectFromRoot(I18N_SERVICE_INJECTION_KEY);
  const authErrorDisplayHandler = () => {
    const { t } = i18n.global;
    errorService.blockDisplay();
    const $systemStore = useSystemStore();
    $systemStore.stopHealthCheck();
    DialogPlugin.create({
      component: ConfirmDialog,
      componentProps: {
        title: t(ECommonMessages.ERROR_AUTH_TITLE),
        icon: "traffic-cone",
        iconColor: "r400",
        iconBgColor: "r50",
        message: t(ECommonMessages.ERROR_AUTH_MESSAGE),
        okBtn: {
          label: t(ECommonMessages.RELOGIN_BUTTON_TITLE),
          iconRight: "arrow-sm-right",
        },
      },
    }).onOk(() => {
      window.location.href = "/";
    });
  };

  errorService.registerErrorDisplay(EErrorType.AUTHENTICATION, {
    display: authErrorDisplayHandler,
  });
};
