import { ECommonMessages, type IAppError } from "../../../types";
import { NotifyPlugin } from "draivn-ui-kit";
import {
  EErrorType,
  type ErrorService,
  type IApiError,
} from "draivn-ui-domain";
import { I18N_SERVICE_INJECTION_KEY } from "@/constants";
import { injector } from "@/services";

export const alreadyExistDisplayHandlerInit = (errorService: ErrorService) => {
  const i18n = injector.injectFromRoot(I18N_SERVICE_INJECTION_KEY);
  const validationErrorDisplayHandler = (error: IApiError) => {
    const { t } = i18n.global;
    errorService.blockDisplay();
    NotifyPlugin.create({
      message: t(ECommonMessages.ERROR_DEFAULT_TITLE),
      caption: (error as IAppError<IApiError>).data[0].reason,
      type: "draivn-toast-warning",
      icon: "error_outline",
      onDismiss() {
        errorService.unblockDisplay();
      },
    });
  };

  errorService.registerErrorDisplay(EErrorType.CREATION, {
    display: validationErrorDisplayHandler,
  });
};
