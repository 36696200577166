export enum EMessagesKeys {
  HELP_CENTER_TITLE = "HELP_CENTER.HELP_CENTER_TITLE",
  HELP_CENTER_SIDE_TITLE = "HELP_CENTER.HELP_CENTER_SIDE_TITLE",
  HELP_CENTER_SIDE_OPEN_EXTERNAL = "HELP_CENTER.HELP_CENTER_SIDE_OPEN_EXTERNAL",
  HELP_CENTER_SIDE_OPEN_EXTERNAL_TOOLTIP = "HELP_CENTER.HELP_CENTER_SIDE_OPEN_EXTERNAL_TOOLTIP",
  CONTACT_SUPPORT_TITLE = "HELP_CENTER.CONTACT_SUPPORT_TITLE",
  CONTACT_SUPPORT_SUBTITLE = "HELP_CENTER.CONTACT_SUPPORT_SUBTITLE",
  CONTACT_SUPPORT_REQUEST_TITLE = "HELP_CENTER.CONTACT_SUPPORT_REQUEST_TITLE",
  CONTACT_SUPPORT_REQUEST_SUBTITLE = "HELP_CENTER.CONTACT_SUPPORT_REQUEST_SUBTITLE",
  CONTACT_SUPPORT_REQUEST_EMAIL_TITLE = "HELP_CENTER.CONTACT_SUPPORT_REQUEST_EMAIL_TITLE",
  CONTACT_SUPPORT_REQUEST_SUBJECT_TITLE = "HELP_CENTER.CONTACT_SUPPORT_REQUEST_SUBJECT_TITLE",
  CONTACT_SUPPORT_REQUEST_DESCRIPTION_TITLE = "HELP_CENTER.CONTACT_SUPPORT_REQUEST_DESCRIPTION_TITLE",
  CONTACT_SUPPORT_REQUEST_MESSAGE = "HELP_CENTER.CONTACT_SUPPORT_REQUEST_MESSAGE",
  CONTACT_SUPPORT_REQUEST_SUBMIT_TITLE = "HELP_CENTER.CONTACT_SUPPORT_REQUEST_SUBMIT_TITLE",
  CONTACT_SUPPORT_REQUEST_CANCEL_TITLE = "HELP_CENTER.CONTACT_SUPPORT_REQUEST_CANCEL_TITLE",
  CONTACT_SUPPORT_CHAT_TITLE = "HELP_CENTER.CONTACT_SUPPORT_CHAT_TITLE",
  REQUEST_DEMO_TITLE = "HELP_CENTER.REQUEST_DEMO_TITLE",
  REQUEST_DEMO_SUBTITLE = "HELP_CENTER.REQUEST_DEMO_SUBTITLE",
  REQUEST_DEMO_DIALOG_TITLE = "HELP_CENTER.REQUEST_DEMO_DIALOG_TITLE",
  REQUEST_DEMO_DIALOG_SUBTITLE = "HELP_CENTER.REQUEST_DEMO_DIALOG_SUBTITLE",
  REQUEST_DEMO_DIALOG_FIRSTNAME_TITLE = "HELP_CENTER.REQUEST_DEMO_DIALOG_FIRSTNAME_TITLE",
  REQUEST_DEMO_DIALOG_LASTNAME_TITLE = "HELP_CENTER.REQUEST_DEMO_DIALOG_LASTNAME_TITLE",
  REQUEST_DEMO_DIALOG_COMPANY_NAME_TITLE = "HELP_CENTER.REQUEST_DEMO_DIALOG_COMPANY_NAME_TITLE",
  REQUEST_DEMO_DIALOG_EMAIL_TITLE = "HELP_CENTER.REQUEST_DEMO_DIALOG_EMAIL_TITLE",
  REQUEST_DEMO_DIALOG_PHONE_TITLE = "HELP_CENTER.REQUEST_DEMO_DIALOG_PHONE_TITLE",
  REQUEST_DEMO_DIALOG_INDUSTRY_TITLE = "HELP_CENTER.REQUEST_DEMO_DIALOG_INDUSTRY_TITLE",
  REQUEST_DEMO_DIALOG_INDUSTRY_PLACEHOLDER = "HELP_CENTER.REQUEST_DEMO_DIALOG_INDUSTRY_PLACEHOLDER",
  REQUEST_DEMO_DIALOG_SUBMIT_TITLE = "HELP_CENTER.REQUEST_DEMO_DIALOG_SUBMIT_TITLE",
  REQUEST_DEMO_DIALOG_CANCEL_TITLE = "HELP_CENTER.REQUEST_DEMO_DIALOG_CANCEL_TITLE",
  SEARCH_TITLE = "HELP_CENTER.SEARCH_TITLE",
  SEARCH_SUBTITLE = "HELP_CENTER.SEARCH_SUBTITLE",
  SEARCH_NOTHING_FOUND = "HELP_CENTER.SEARCH_NOTHING_FOUND",
  SEARCH_PLACEHOLDER = "HELP_CENTER.SEARCH_PLACEHOLDER",
  FAQ_TITLE = "HELP_CENTER.FAQ_TITLE",
  KNOWLEDGEBASE_TITLE = "HELP_CENTER.KNOWLEDGEBASE_TITLE",
  GUIDES_TITLE = "HELP_CENTER.GUIDES_TITLE",
  VIEW_MORE_LINK = "HELP_CENTER.VIEW_MORE_LINK",
  DATA_AND_INTEGRAIONS_TITLE = "HELP_CENTER.DATA_AND_INTEGRAIONS_TITLE",
  DATA_AND_INTEGRAIONS_SUBTITLE = "HELP_CENTER.DATA_AND_INTEGRAIONS_SUBTITLE",
  DEVELOPER_API_TITLE = "HELP_CENTER.DEVELOPER_API_TITLE",
  DEVELOPER_API_SUBTITLE = "HELP_CENTER.DEVELOPER_API_SUBTITLE",
  HELP_MESSAGE_TITLE = "HELP_CENTER.HELP_MESSAGE_TITLE",
  HELP_MESSAGE_SUBTITLE = "HELP_CENTER.HELP_MESSAGE_SUBTITLE",
  DEVELOPER_API_DESCRIPTION = "HELP_CENTER.DEVELOPER_API_DESCRIPTION",
  DATA_AND_INTEGRATION_DESCRIPTION = "HELP_CENTER.DATA_AND_INTEGRATION_DESCRIPTION",

  VALIDATORS_REQUIRED_COMMON_MESSAGE = "HELP_CENTER.VALIDATORS_REQUIRED_COMMON_MESSAGE",
  VALIDATORS_EMAIL_COMMON_MESSAGE = "HELP_CENTER.VALIDATORS_EMAIL_COMMON_MESSAGE",
}
