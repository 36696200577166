import { LOCALES } from "~/types";
import { createI18n } from "vue-i18n";
import type { App } from "vue";
import { I18N_SERVICE_INJECTION_KEY } from "@/constants";
import { injector } from "@/services/injector";

export const i18nService = createI18n<[], LOCALES>({
  locale: LOCALES.EN_US,
  legacy: false,
});

export default (app: App) => {
  injector.provide(I18N_SERVICE_INJECTION_KEY, i18nService);
  app.provide(I18N_SERVICE_INJECTION_KEY, i18nService);
  app.use(i18nService);
  return i18nService;
};
