import type { IMapper } from "~/types";
import type { IMenuItem, IMenuItemResponseModel } from "../../types";

export const menuItemMapper: IMapper<IMenuItemResponseModel, IMenuItem> = (
  value
) => value;

export const menuItemListMapper: IMapper<
  IMenuItemResponseModel[],
  IMenuItem[]
> = (value: IMenuItemResponseModel[]) => value.map(menuItemMapper);
