import {
  API_SERVICE_INJECTION_KEY,
  I18N_SERVICE_INJECTION_KEY,
} from "@/constants";
import { LOCALES } from "~/types";
import { injector } from "@/services";
import { II18NMessage, II18NMessageResponseModel } from "../../types";
import { i18nMessageListMapper } from "../mapping";
import { set } from "draivn-ui-domain";

const ONBOARDING_I18N_PREFIX = "ONBOARDING_APP.";

export const getI18NMessages = async () => {
  const apiService = injector.injectFromRoot(API_SERVICE_INJECTION_KEY);
  let result: II18NMessage[] | null = null;
  const params = { tags: "onboarding", limit: 1000 };
  const response = await apiService.get<II18NMessageResponseModel[]>(
    "/help/records",
    params
  );
  if (apiService.isRequestSuccess(response) && response.data) {
    const data = response.data;
    result = i18nMessageListMapper(data);
  }
  return result;
};

export default async () => {
  const i18n = injector.injectFromRoot(I18N_SERVICE_INJECTION_KEY);
  if (i18n) {
    const response = await getI18NMessages();
    let messages: Record<string, string> | null = null;
    if (response && response.length) {
      messages = response.reduce(
        (acc: Record<string, string>, item: II18NMessage) => {
          const key = item.key.replace(ONBOARDING_I18N_PREFIX, "");
          set(acc, key, item.value);
          return acc;
        },
        {}
      );
      if (messages) {
        i18n.global.mergeLocaleMessage(LOCALES.EN_US, messages);
      }
    }
  }
};
