import { LOCALES } from "~/types";
import { MESSAGES } from "./messages";
import { injector } from "@/services";
import { I18N_SERVICE_INJECTION_KEY } from "@/constants";

export default () => {
  const i18n = injector.injectFromRoot(I18N_SERVICE_INJECTION_KEY);
  if (i18n) {
    i18n.global.mergeLocaleMessage(LOCALES.EN_US, {
      HELP_CENTER: MESSAGES,
    });
  }
};
