import type { IMapper } from "~/types";
import type {
  ICollectionItem,
  ICollectionItemResponseModel,
} from "../../types";

export const collectionItemMapper: IMapper<
  ICollectionItemResponseModel,
  ICollectionItem
> = (value) => value;

export const collectionItemListMapper: IMapper<
  ICollectionItemResponseModel[],
  ICollectionItem[]
> = (value: ICollectionItemResponseModel[]) => value.map(collectionItemMapper);
