import { Component } from "vue";
import { ELayoutRegisteredItems } from "../../types";

export class LayoutService {
  private layout: Partial<Record<ELayoutRegisteredItems, Component>> = {};

  public registerItem(key: ELayoutRegisteredItems, component: Component) {
    this.layout[key] = component;
  }

  public unregisterItem(key: ELayoutRegisteredItems) {
    delete this.layout[key];
  }

  public getItem(key: ELayoutRegisteredItems) {
    return this.layout[key];
  }
}
