import { injector } from "@/services";
import { API_SERVICE_INJECTION_KEY } from "@/constants";
import { IRevokeRequestParams } from "../../types";

export const deleteRevokeConnection = async (config: IRevokeRequestParams) => {
  const apiService = injector.injectFromRoot(API_SERVICE_INJECTION_KEY);
  let result: boolean | null = null;
  const response = await apiService.delete<string[]>(
    `api/v1/revoke/${config.type}/${config.tokenId}`
  );
  if (apiService.isRequestSuccess(response) && response.data) {
    result = true;
  }
  return result;
};
