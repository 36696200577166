export const MESSAGES = {
  HELP_CENTER_TITLE: "Help Center",
  HELP_CENTER_SIDE_TITLE: "Help",
  CONTACT_SUPPORT_TITLE: "Contact support",
  CONTACT_SUPPORT_SUBTITLE: "Get in touch with our 24/7 Customer Support.",
  CONTACT_SUPPORT_REQUEST_TITLE: "Submit a request",
  CONTACT_SUPPORT_REQUEST_SUBTITLE: "Tell us how we can help.",
  CONTACT_SUPPORT_REQUEST_EMAIL_TITLE: "Your email address",
  CONTACT_SUPPORT_REQUEST_SUBJECT_TITLE: "Subject",
  CONTACT_SUPPORT_REQUEST_DESCRIPTION_TITLE: "Description",
  CONTACT_SUPPORT_REQUEST_MESSAGE: "Still have questions?",
  CONTACT_SUPPORT_REQUEST_SUBMIT_TITLE: "Submit",
  CONTACT_SUPPORT_REQUEST_CANCEL_TITLE: "Cancel",
  CONTACT_SUPPORT_CHAT_TITLE: "Chat with us",
  REQUEST_DEMO_TITLE: "Request a demo",
  REQUEST_DEMO_SUBTITLE: "Explore how Draivn can work for you.",
  REQUEST_DEMO_DIALOG_TITLE: "Book your personalized demo",
  REQUEST_DEMO_DIALOG_SUBTITLE: "Tell us how we can help.",
  REQUEST_DEMO_DIALOG_FIRSTNAME_TITLE: "First name",
  REQUEST_DEMO_DIALOG_LASTNAME_TITLE: "Last name",
  REQUEST_DEMO_DIALOG_COMPANY_NAME_TITLE: "Company name",
  REQUEST_DEMO_DIALOG_EMAIL_TITLE: "Work email",
  REQUEST_DEMO_DIALOG_PHONE_TITLE: "Mobile phone number",
  REQUEST_DEMO_DIALOG_INDUSTRY_TITLE: "Select your industry",
  REQUEST_DEMO_DIALOG_INDUSTRY_PLACEHOLDER: "Select...",
  REQUEST_DEMO_DIALOG_SUBMIT_TITLE: "Submit",
  REQUEST_DEMO_DIALOG_CANCEL_TITLE: "Cancel",
  SEARCH_TITLE: "Help, resources, and support",
  SEARCH_SUBTITLE: "Support to learn, explore and connect with Draivn",
  SEARCH_PLACEHOLDER: "Search the help center...",
  SEARCH_NOTHING_FOUND: "No results found",
  FAQ_TITLE: "FAQ",
  KNOWLEDGEBASE_TITLE: "Knowledge base",
  GUIDES_TITLE: "User guide",
  VIEW_MORE_LINK: "View more",
  DATA_AND_INTEGRAIONS_TITLE: "Data and Integrations",
  DATA_AND_INTEGRAIONS_SUBTITLE: "Integrate Draivn with other apps",
  DEVELOPER_API_TITLE: "Developer API",
  DEVELOPER_API_SUBTITLE: "Build your own integrations with our API",
  HELP_MESSAGE_TITLE: "Can't find what you're looking for?",
  HELP_MESSAGE_SUBTITLE: "Get in touch with Support team or request a demo",
  HELP_CENTER_SIDE_OPEN_EXTERNAL: "Open",
  HELP_CENTER_SIDE_OPEN_EXTERNAL_TOOLTIP: "Open in new tab",

  DEVELOPER_API_DESCRIPTION: "Build your own integrations with our API",
  DATA_AND_INTEGRATION_DESCRIPTION: "Integrate Draivn with other apps",

  VALIDATORS_REQUIRED_COMMON_MESSAGE: "Field cannot be empty",
  VALIDATORS_EMAIL_COMMON_MESSAGE: "Email is invalid",
};
