export class AclService {
  private abilities: Record<string, string>;

  constructor(private acl: Record<string, string | Record<string, string>>) {
    this.abilities = {};
  }

  public async build(): Promise<undefined> {
    // do your async stuff here
    // now instantiate and return a class
    const acl = {};
    this.acl = acl;
    return undefined;
  }

  public register(key: string, path: string) {
    return false;
  }

  public unregister(key: string) {
    return false;
  }

  public check(key: string) {
    const path = this.abilities[key];
    const result = this.acl[path];
    return true; // result
  }

  public checkMany(keys: string[]) {
    const result = keys.map((key) => this.check(key));
    return result;
  }

  public checkAny(keys: string[]) {
    const result = keys.some((key) => this.check(key));
    return result;
  }

  public checkAll(keys: string[]) {
    const result = keys.every((key) => this.check(key));
    return result;
  }

  public checkNot(key: string) {
    return !this.check(key);
  }

  public checkManyNot(keys: string[]) {
    const result = keys.map((key) => this.checkNot(key));
    return result;
  }

  public checkAnyNot(keys: string[]) {
    const result = keys.some((key) => this.checkNot(key));
    return result;
  }

  public checkAllNot(keys: string[]) {
    const result = keys.every((key) => this.checkNot(key));
    return result;
  }
}
