import { injector } from "@/services";
import { INTERNET_ERROR } from "../../../constants/network-error";
import { useSystemStore } from "../../store";
import { ECommonMessages } from "../../../types";
import { NotifyPlugin } from "draivn-ui-kit";
import {
  EErrorType,
  type ErrorService,
  type IApiError,
} from "draivn-ui-domain";
import { watch } from "vue";
import { I18N_SERVICE_INJECTION_KEY } from "@/constants";
import { storeToRefs } from "pinia";

export const disconnectDisplayHandlerInit = (errorService: ErrorService) => {
  const i18n = injector.injectFromRoot(I18N_SERVICE_INJECTION_KEY);
  const internetErrorDisplayHandler = (error: IApiError) => {
    const isInternetError = (error.data[0] as Error).message === INTERNET_ERROR;
    if (isInternetError) {
      const { t } = i18n.global;
      errorService.blockDisplay();
      const $systemStore = useSystemStore();
      $systemStore.stopHealthCheck();
      const { connectionStatus } = storeToRefs($systemStore);
      const closeNotify = NotifyPlugin.create({
        type: "draivn-toast-warning",
        message: t(ECommonMessages.ERROR_INTERNET_TITLE),
        caption: t(ECommonMessages.ERROR_INTERNET_MESSAGE),
        spinner: true,
      });
      watch(connectionStatus, (value: boolean) => {
        if (value) {
          closeNotify();
          errorService.unblockDisplay();
          $systemStore.startHealthCheck();
        }
      });
    }
  };

  errorService.registerErrorDisplay(EErrorType.LOCAL, {
    display: internetErrorDisplayHandler,
  });
};
