import type { App } from "vue";
import aclServiceInstall from "./acl";
import apiServiceInstall from "./api";
import errorServiceInstall from "./error";
import i18nServiceInstall from "./i18n";
import routerServiceInstall from "./router";
import storeServiceInstall from "./store";
import eventBusInstall from "./event-bus";
import analitycsServiceInstall from "./analitycs";
import uiKitInstall from "./ui-kit";
import configRenderServiceInstall from "./config-render";
import injectorServiceInstall from "./injector";

export * from "./acl";
export * from "./api";
export * from "./error";
export * from "./i18n";
export * from "./router";
export * from "./store";
export * from "./event-bus";
export * from "./analitycs";
export * from "./ui-kit";
export * from "./injector";

export default async (app: App) => {
  const injector = injectorServiceInstall(app);
  const aclService = await aclServiceInstall(app);
  const store = storeServiceInstall(app);
  const apiService = apiServiceInstall(app);
  const configRenderService = await configRenderServiceInstall(app);
  const eventBus = eventBusInstall(app);
  const errorService = errorServiceInstall(app);
  const i18nService = i18nServiceInstall(app);
  const router = routerServiceInstall(app);
  await analitycsServiceInstall(app);
  await uiKitInstall(app);

  return {
    configRenderService,
    eventBus,
    aclService,
    errorService,
    apiService,
    i18nService,
    store,
    router,
    injector,
  };
};
