<template>
  <div class="draivn-page-loading absolute-center full-height full-width">
    <d-circular-progress
      class="absolute-center q-mx-xs"
      size="48px"
      color="blue-grey-8"
    />
  </div>
</template>

<style lang="scss" scoped>
.draivn-page-loading {
  opacity: 0.5;
  z-index: 1;
}
</style>
