import { IModuleParams } from "~/types";
import { ROUTE } from "./routes";
import { router } from "@/services/router";

export default async ({
  mountRouteName,
}: NonNullable<IModuleParams["routerConfig"]>) => {
  const mainRoute = router
    .getRoutes()
    .find((route) => route.name === mountRouteName);
  if (mainRoute) {
    const newMainRoute = { ...mainRoute };
    newMainRoute.children = [...newMainRoute.children, ROUTE];
    router.removeRoute(mountRouteName);
    router.addRoute(newMainRoute);
  }
};
