import { InjectionKey, inject } from "vue";

export class Injector {
  private servicesMap: Map<InjectionKey<unknown>, unknown> = new Map();

  public injectFromRoot<T>(key: InjectionKey<T>): T {
    const value = this.servicesMap.get(key) as T;
    if (value === undefined) {
      console.trace();
      throw new Error(`Symbol ${key.toString()} is not provided`);
    }
    return value;
  }

  public inject<T>(key: InjectionKey<T>): T {
    const value = inject(key) as T;
    if (value === undefined) {
      throw new Error(`Symbol ${key.toString()} is not provided`);
    }
    return value;
  }

  public provide<T>(key: InjectionKey<T>, service: T) {
    this.servicesMap.set(key, service);
  }
}
