import {
  EInsurerMessagesKeys,
  EStepsName,
  ESummaryMessagesKeys,
  ETelematicsMessagesKeys,
  IOnboardingModel,
  IStepRenderModel,
} from "../types";
import { get } from "draivn-ui-domain";
import { getAsyncComponent } from "@/modules/app";
import {
  postInsurerStep,
  postTelematicsStep,
  postOnboardingComplete,
} from "../services";
import { TELEMATICS_EMPTY_MODEL } from "./telematics-empty-model";

const getData = (
  key: EStepsName,
  data: IOnboardingModel,
  defaultValue: any = null
) => {
  const value = get(data, `sections[${key}].data`) || defaultValue;
  return value;
};
// TODO: refactor  - fields title and pageTitle to main config
export const getSteps = (model: IOnboardingModel) => {
  const steps = new Map<EStepsName, IStepRenderModel>();
  if (model.all_sections.includes(EStepsName.INSURER)) {
    steps.set(EStepsName.INSURER, {
      type: EStepsName.INSURER,
      pageTitle: EInsurerMessagesKeys.TITLE,
      title: EInsurerMessagesKeys.TABNAME,
      component: getAsyncComponent(
        () => import("../components/steps/CompanyStep.vue")
      ),
      data: getData(EStepsName.INSURER, model),
      save: postInsurerStep,
      valid: model.sections[EStepsName.INSURER]?.valid || false,
    });
  }
  if (model.all_sections.includes(EStepsName.TELEMATICS)) {
    steps.set(EStepsName.TELEMATICS, {
      type: EStepsName.TELEMATICS,
      pageTitle: ETelematicsMessagesKeys.TITLE,
      title: ETelematicsMessagesKeys.TABNAME,
      component: getAsyncComponent(
        () => import("../components/steps/TelematicsStep.vue")
      ),
      data: getData(EStepsName.TELEMATICS, model, [
        structuredClone(TELEMATICS_EMPTY_MODEL),
      ]),
      save: postTelematicsStep,
      valid: model.sections[EStepsName.TELEMATICS]?.valid || false,
    });
  }
  if (model.all_sections.includes(EStepsName.SUMMARY)) {
    steps.set(EStepsName.SUMMARY, {
      type: EStepsName.SUMMARY,
      pageTitle: ESummaryMessagesKeys.TITLE,
      title: ESummaryMessagesKeys.TABNAME,
      component: getAsyncComponent(
        () => import("../components/steps/SummaryStep.vue")
      ),
      data: getData(EStepsName.SUMMARY, model),
      save: postOnboardingComplete,
      valid: model.sections[EStepsName.SUMMARY]?.valid || false,
    });
  }
  return steps;
};
