import i18nInstall from "./i18n";
import storeInstall from "./store";
import layoutInstall from "./layout";
import apiInstall from "./api";
import routerInstall from "./router";
import { IModuleParams } from "~/types";

export * from "./store";

export let api: ReturnType<typeof apiInstall>;

export default async (params: IModuleParams | void) => {
  const { routerConfig, apiConfig } = params ?? {};
  if (routerConfig) {
    await routerInstall(routerConfig);
  }
  const localApi = apiInstall(
    apiConfig ?? {
      baseUrl: "/mediator/api/v1/help-center",
    }
  );
  api = localApi;
  layoutInstall();
  const stores = storeInstall();
  i18nInstall();

  return {
    stores,
    api: localApi,
  };
};
