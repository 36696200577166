import { IOnboardingModel, IOnboardingSession } from "../../../../../types";
import { getSession, getOnboardingModel } from "../../../../api/index";
import { API_SERVICE_INJECTION_KEY } from "@/constants";
import { injector } from "@/services";
import { ref } from "vue";

const AUTH_HEADER_NAME = "X-AccessToken";
export const useStateFeature = () => {
  const token = ref<string | null>(null);
  const sessionId = ref<string | null>(null);
  const setToken = (value: string | null) => {
    token.value = value;
  };

  const initSession = async () => {
    let session: IOnboardingSession | null = null;
    if (token.value) {
      const apiService = injector.injectFromRoot(API_SERVICE_INJECTION_KEY);
      session = await getSession(token.value);
      const config = apiService.config;
      if (session && config) {
        if (config.headers) {
          config.headers[AUTH_HEADER_NAME] = session.session_id;
          sessionId.value = session.session_id;
        }
        apiService.config = config;
      }
    }
    return session;
  };

  const model = ref<IOnboardingModel | null>(null);

  const getModel = async () => {
    model.value = await getOnboardingModel();
    return model.value;
  };

  return {
    token,
    sessionId,
    setToken,
    initSession,
    model,
    getModel,
  };
};
