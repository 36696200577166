import { defineStore } from "pinia";
import { helpCenterStoreInstall } from "./modules";

export * from "./modules";

export const useHelpCenterStore = defineStore(
  "help-center",
  helpCenterStoreInstall
);

export default () => {
  return {
    useHelpCenterStore,
  };
};
