import { sessionStorage } from "draivn-ui-domain";
import { defineStore } from "pinia";
import { onboardingStateStoreInstall } from "./modules";

export const useOnboardingStore = defineStore(
  "onboarding-state",
  onboardingStateStoreInstall,
  {
    persist: {
      storage: sessionStorage,
      debounce: 100,
    },
  }
);

export default () => {
  return {
    useOnboardingStore,
  };
};
