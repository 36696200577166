import { IRenderConfig, IConfigEntity } from "~/types";
import { get, set } from "draivn-ui-domain";

export class ConfigRenderService {
  private config: IRenderConfig | undefined;
  private abilities: Record<string, string>;

  constructor() {
    this.abilities = {};
  }

  public async build(): Promise<undefined> {
    this.config = {};
    return undefined;
  }

  public register(key: string, path: string) {
    let result;
    if (!this.abilities[key]) {
      this.abilities[key] = path;
      result = true;
    } else {
      console.warn(`Ability ${key} already registered`);
      result = false;
    }
    return result;
  }

  public unregister(key: string) {
    delete this.abilities[key];
    return true;
  }

  public getConfig<T = unknown>(key: string): T | undefined {
    const path = this.abilities[key];
    let result: IConfigEntity<T> | undefined;
    if (path && this.config) {
      result = get(this.config, path);
    }
    return result && result.config;
  }

  public addToConfig<T = unknown>(key: string, value: T) {
    const path = this.abilities[key].split(".");
    if (path) {
      let config = this.config as Record<string, unknown>;
      path.forEach((key, index) => {
        if (index === path.length - 1) {
          set(config, key, {
            name: key,
            config: value,
          });
        } else {
          if (!config[key]) {
            config[key] = {
              name: key,
              children: {},
            };
          }
          config = config[key] as Record<string, unknown>;
        }
      });
    }
    return true;
  }

  public check(key: string): boolean {
    const path = this.abilities[key];
    let result;
    if (path && this.config) {
      result = get(this.config, path);
    }
    return !!result;
  }

  public checkMany(keys: string[]) {
    const result = keys.map((key) => this.check(key));
    return result;
  }

  public checkAny(keys: string[]) {
    const result = keys.some((key) => this.check(key));
    return result;
  }

  public checkAll(keys: string[]) {
    const result = keys.every((key) => this.check(key));
    return result;
  }

  public checkNot(key: string) {
    return !this.check(key);
  }

  public checkManyNot(keys: string[]) {
    const result = keys.map((key) => this.checkNot(key));
    return result;
  }

  public checkAnyNot(keys: string[]) {
    const result = keys.some((key) => this.checkNot(key));
    return result;
  }

  public checkAllNot(keys: string[]) {
    const result = keys.every((key) => this.checkNot(key));
    return result;
  }
}
