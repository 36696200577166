import type { App } from "vue";
import { ErrorService } from "draivn-ui-domain";
import { ERROR_SERVICE_INJECTION_KEY } from "@/constants";
import { injector } from "@/services/injector";

export const errorService = new ErrorService();

export default (app: App) => {
  injector.provide(ERROR_SERVICE_INJECTION_KEY, errorService);
  app.provide(ERROR_SERVICE_INJECTION_KEY, errorService);
  return errorService;
};
