import { api } from "../../../..";
import { ref } from "vue";
import type { IArticle } from "../../../../../types";
import { IRequestConfig } from "draivn-ui-domain";
export const useArticleFeature = () => {
  const article = ref<IArticle | null>(null);

  const initArticleByAlias = async (
    group: string,
    alias: string,
    config?: IRequestConfig
  ) => {
    const currentMenu = await api.getArticle(group, alias, config);
    article.value = currentMenu;
    return article.value;
  };

  const clearArticle = () => {
    article.value = null;
  };

  return {
    article,
    initArticleByAlias,
    clearArticle,
  };
};
