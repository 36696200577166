import i18nInstall from "./i18n";
import storeInstall from "./store";
import errorInstall from "./error";
import configRenderInstall from "./config-render";
import layoutInstall from "./layout";
import routerInstall from "./router";

export * from "./store";

export default async () => {
  await routerInstall();
  layoutInstall();
  configRenderInstall();
  const stores = storeInstall();
  errorInstall();
  await i18nInstall();

  return {
    stores,
  };
};
