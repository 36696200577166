import servicesInstall from "./services";
export * from "./composables";

export {
  SYSTEM_STORE_INJECTION_KEY,
  ROUTE_MAIN_NAME,
  APP_LAYOUT_SERVICE_INJECTION_TOKEN,
  LOGO_BLOCK_RENDER_FEATURE_KEY,
  LOGO_BLOCK_RENDER_FEATURE_PATH,
  SUPPORT_INFO_RENDER_FEATURE_KEY,
  SUPPORT_INFO_RENDER_FEATURE_PATH,
} from "./constants";

export { useSystemStore } from "./services";

export { default as AppLoading } from "./components/common/AppLoading.vue";
export { default as ConfirmDialog } from "./components/common/ConfirmDialog.vue";
export { default as LoadingWrapper } from "./components/common/LoadingWrapper.vue";

export { default as MainComponent } from "./components/MainComponent.vue";

export * from "./composables";

export * from "./types";

export default async () => {
  await servicesInstall();
};
