export enum ETelematicsMessagesKeys {
  TITLE = "ONBOARDING.TELEMATICS.TITLE",
  TABNAME = "ONBOARDING.TELEMATICS.TABNAME",
  API_TITLE = "ONBOARDING.TELEMATICS.API_TITLE",
  SKIP_TITLE = "ONBOARDING.TELEMATICS.SKIP_TITLE",
  SKIP_HINT = "ONBOARDING.TELEMATICS.SKIP_HINT",
  EMAIL_COLLEAGUE_TITLE = "ONBOARDING.TELEMATICS.EMAIL_COLLEAGUE_TITLE",

  SAMSARA_URL_HINT = "ONBOARDING.TELEMATICS.SAMSARA_URL_HINT",

  TSP_SELECTOR_TITLE = "ONBOARDING.TELEMATICS.TSP_SELECTOR_TITLE",
  TSP_SELECTOR_EMPTY_TITLE = "ONBOARDING.TELEMATICS.TSP_SELECTOR_EMPTY_TITLE",
  TSP_SELECTOR_SEARCH_PLACEHOLDER = "ONBOARDING.TELEMATICS.TSP_SELECTOR_SEARCH_PLACEHOLDER",
  TSP_SELECTOR_SEARCH_NO_OPTIONS_LABEL = "ONBOARDING.TELEMATICS.TSP_SELECTOR_SEARCH_NO_OPTIONS_LABEL",
  TSP_METHOD_SELECT_TITLE = "ONBOARDING.TELEMATICS.TSP_METHOD_SELECT_TITLE",

  TOKEN_METHOD_OPTION_TITLE = "ONBOARDING.TELEMATICS.TOKEN_METHOD_OPTION_TITLE",
  TOKEN_METHOD_OPTION_SUBTITLE = "ONBOARDING.TELEMATICS.TOKEN_METHOD_OPTION_SUBTITLE",
  BASIC_METHOD_OPTION_TITLE = "ONBOARDING.TELEMATICS.BASIC_METHOD_OPTION_TITLE",
  BASIC_METHOD_OPTION_SUBTITLE = "ONBOARDING.TELEMATICS.BASIC_METHOD_OPTION_SUBTITLE",

  BASE_TOKEN_TITLE = "ONBOARDING.TELEMATICS.BASE_TOKEN_TITLE",
  BASE_TOKEN_SUBTITLE_PART_1 = "ONBOARDING.TELEMATICS.BASE_TOKEN_SUBTITLE_PART_1",
  BASE_TOKEN_SUBTITLE_PART_2 = "ONBOARDING.TELEMATICS.BASE_TOKEN_SUBTITLE_PART_2",
  BASE_TOKEN_SUBTITLE_URL_TITLE = "ONBOARDING.TELEMATICS.BASE_TOKEN_SUBTITLE_URL_TITLE",
  BASE_TOKEN_INPUT_TITLE = "ONBOARDING.TELEMATICS.BASE_TOKEN_INPUT_TITLE",
  CHECK_CONNECTION_TITLE = "ONBOARDING.TELEMATICS.CHECK_CONNECTION_TITLE",
  CHECK_CONNECTION_CONNECTED_STATUS = "ONBOARDING.TELEMATICS.CHECK_CONNECTION_CONNECTED_STATUS",

  BASE_BASIC_TITLE = "ONBOARDING.TELEMATICS.BASE_BASIC_TITLE",
  BASE_BASIC_SUBTITLE = "ONBOARDING.TELEMATICS.BASE_BASIC_SUBTITLE",
  BASE_BASIC_USERNAME_TITLE = "ONBOARDING.TELEMATICS.BASE_BASIC_USERNAME_TITLE",
  BASE_BASIC_PASSWORD_TITLE = "ONBOARDING.TELEMATICS.BASE_BASIC_PASSWORD_TITLE",
  BASE_BASIC_INFO = "ONBOARDING.TELEMATICS.BASE_BASIC_INFO",

  TRIMBLE_BASIC_TITLE = "ONBOARDING.TELEMATICS.TRIMBLE_BASIC_TITLE",
  TRIMBLE_BASIC_SUBTITLE = "ONBOARDING.TELEMATICS.TRIMBLE_BASIC_SUBTITLE",
  TRIMBLE_BASIC_USERNAME_TITLE = "ONBOARDING.TELEMATICS.TRIMBLE_BASIC_USERNAME_TITLE",
  TRIMBLE_BASIC_PASSWORD_TITLE = "ONBOARDING.TELEMATICS.TRIMBLE_BASIC_PASSWORD_TITLE",
  TRIMBLE_BASIC_CID_HINT = "ONBOARDING.TELEMATICS.TRIMBLE_BASIC_CID_HINT",

  WAYLENS_BASIC_TITLE = "ONBOARDING.TELEMATICS.WAYLENS_BASIC_TITLE",
  WAYLENS_BASIC_SUBTITLE = "ONBOARDING.TELEMATICS.WAYLENS_BASIC_SUBTITLE",
  WAYLENS_BASIC_USERNAME_TITLE = "ONBOARDING.TELEMATICS.WAYLENS_BASIC_USERNAME_TITLE",
  WAYLENS_BASIC_PASSWORD_TITLE = "ONBOARDING.TELEMATICS.WAYLENS_BASIC_PASSWORD_TITLE",

  BASE_ACCOUNT_NAME_INSTRUCTION_1_TITLE = "ONBOARDING.TELEMATICS.BASE_ACCOUNT_NAME_INSTRUCTION_1_TITLE",
  BASE_ACCOUNT_NAME_INSTRUCTION_1_SUBTITLE = "ONBOARDING.TELEMATICS.BASE_ACCOUNT_NAME_INSTRUCTION_1_SUBTITLE",
  BASE_ACCOUNT_NAME_INSTRUCTION_2_TITLE = "ONBOARDING.TELEMATICS.BASE_ACCOUNT_NAME_INSTRUCTION_2_TITLE",
  BASE_ACCOUNT_NAME_FIELD_TITLE = "ONBOARDING.TELEMATICS.BASE_ACCOUNT_NAME_FIELD_TITLE",
  BASE_ACCOUNT_NAME_WO_CONF_CREDS_BANER = "ONBOARDING.TELEMATICS.BASE_ACCOUNT_NAME_WO_CONF_CREDS_BANER",
  BASE_ACCOUNT_NAME_INSTRUCTION_CONFIRMATION_TITLE = "ONBOARDING.TELEMATICS.BASE_ACCOUNT_NAME_INSTRUCTION_CONFIRMATION_TITLE",
  BASE_ACCOUNT_NAME_INPUT_TITLE = "ONBOARDING.TELEMATICS.BASE_ACCOUNT_NAME_INPUT_TITLE",

  BASE_DATABASE_INSTRUCTION_TITLE = "ONBOARDING.TELEMATICS.BASE_DATABASE_INSTRUCTION_TITLE",
  BASE_DATABASE_FIELD_TITLE = "ONBOARDING.TELEMATICS.BASE_DATABASE_FIELD_TITLE",

  BASE_USERNAME_TOKEN_INSTRUCTION_1_TITLE = "ONBOARDING.TELEMATICS.BASE_USERNAME_TOKEN_INSTRUCTION_1_TITLE",
  BASE_USERNAME_TOKEN_USERNAME_TITLE = "ONBOARDING.TELEMATICS.BASE_USERNAME_TOKEN_USERNAME_TITLE",
  BASE_USERNAME_TOKEN_INSTRUCTION_2_TITLE = "ONBOARDING.TELEMATICS.BASE_USERNAME_TOKEN_INSTRUCTION_2_TITLE",
  BASE_USERNAME_TOKEN_TITLE = "ONBOARDING.TELEMATICS.BASE_USERNAME_TOKEN_TITLE",

  WEBFLEET_ACCOUNT_NAME_INSTRUCTION_1_TITLE = "ONBOARDING.TELEMATICS.WEBFLEET_ACCOUNT_NAME_INSTRUCTION_1_TITLE",
  VERIZON_ACCOUNT_NAME_INSTRUCTION_1_TITLE = "ONBOARDING.TELEMATICS.VERIZON_ACCOUNT_NAME_INSTRUCTION_1_TITLE",
  VERIZON_ACCOUNT_NAME_HINT = "ONBOARDING.TELEMATICS.VERIZON_ACCOUNT_NAME_HINT",
  WEBFLEET_ACCOUNT_NAME_HINT = "ONBOARDING.TELEMATICS.WEBFLEET_ACCOUNT_NAME_HINT",
  VERIZON_ACCOUNT_NAME_INSTRUCTION_2_TITLE = "ONBOARDING.TELEMATICS.VERIZON_ACCOUNT_NAME_INSTRUCTION_2_TITLE",
  VERIZON_ACCOUNT_NAME_TITLE = "ONBOARDING.TELEMATICS.VERIZON_ACCOUNT_NAME_TITLE",
  VERIZON_ACCOUNT_NAME_INSTRUCTION_CONFIRMATION_TITLE = "ONBOARDING.TELEMATICS.VERIZON_ACCOUNT_NAME_INSTRUCTION_CONFIRMATION_TITLE",

  WEBFLEET_BASIC_TITLE = "ONBOARDING.TELEMATICS.WEBFLEET_BASIC_TITLE",
  WEBFLEET_BASIC_SUBTITLE = "ONBOARDING.TELEMATICS.WEBFLEET_BASIC_SUBTITLE",
  WEBFLEET_BASIC_ACCOUNT_NAME_TITLE = "ONBOARDING.TELEMATICS.WEBFLEET_BASIC_ACCOUNT_NAME_TITLE",
  WEBFLEET_BASIC_USERNAME_TITLE = "ONBOARDING.TELEMATICS.WEBFLEET_BASIC_USERNAME_TITLE",
  WEBFLEET_BASIC_PASSWORD_TITLE = "ONBOARDING.TELEMATICS.WEBFLEET_BASIC_PASSWORD_TITLE",

  ISAAC_ACCOUNT_NAME_TITLE = "ONBOARDING.TELEMATICS.ISAAC_ACCOUNT_NAME_TITLE",
  ISAAC_ACCOUNT_NAME_FIELD_TITLE = "ONBOARDING.TELEMATICS.ISAAC_ACCOUNT_NAME_FIELD_TITLE",

  GEOTAB_BASIC_TITLE = "ONBOARDING.TELEMATICS.GEOTAB_BASIC_TITLE",
  GEOTAB_BASIC_SUBTITLE = "ONBOARDING.TELEMATICS.GEOTAB_BASIC_SUBTITLE",
  GEOTAB_BASIC_PLATFORM_URL_TITLE = "ONBOARDING.TELEMATICS.GEOTAB_BASIC_PLATFORM_URL_TITLE",
  GEOTAB_URL_HINT = "ONBOARDING.TELEMATICS.GEOTAB_URL_HINT",
  GEOTAB_DATABASE_HINT = "ONBOARDING.TELEMATICS.GEOTAB_DATABASE_HINT",
  GEOTAB_DATABASE_TITLE = "ONBOARDING.TELEMATICS.GEOTAB_DATABASE_TITLE",

  GPS_INSIGHT_ACCOUNT_NAME_TITLE = "ONBOARDING.TELEMATICS.GPS_INSIGHT_ACCOUNT_NAME_TITLE",
  GPS_INSIGHT_INSTRUCTIONS_2_URL = "ONBOARDING.TELEMATICS.GPS_INSIGHT_INSTRUCTIONS_2_URL",
  GPS_INSIGHT_ACCOUNT_NAME_SUBTITLE = "ONBOARDING.TELEMATICS.GPS_INSIGHT_ACCOUNT_NAME_SUBTITLE",
  GPS_INSIGHT_ACCOUNT_NAME_ACCOUNT_NUMBER_TITLE = "ONBOARDING.TELEMATICS.GPS_INSIGHT_ACCOUNT_NAME_ACCOUNT_NUMBER_TITLE",
  GPS_INSIGHT_TOKEN_INSTRUCTION_1_TITLE = "ONBOARDING.TELEMATICS.GPS_INSIGHT_TOKEN_INSTRUCTION_1_TITLE",
  GPS_INSIGHT_TOKEN_INSTRUCTION_1_SUBTITLE = "ONBOARDING.TELEMATICS.GPS_INSIGHT_TOKEN_INSTRUCTION_1_SUBTITLE",
  GPS_INSIGHT_TOKEN_USERNAME_TITLE = "ONBOARDING.TELEMATICS.GPS_INSIGHT_TOKEN_USERNAME_TITLE",
  GPS_INSIGHT_TOKEN_INSTRUCTION_2_TITLE = "ONBOARDING.TELEMATICS.GPS_INSIGHT_TOKEN_INSTRUCTION_2_TITLE",
  GPS_INSIGHT_TOKEN_INSTRUCTION_2_SUBTITLE = "ONBOARDING.TELEMATICS.GPS_INSIGHT_TOKEN_INSTRUCTION_2_SUBTITLE",
  GPS_INSIGHT_TOKEN_TITLE = "ONBOARDING.TELEMATICS.GPS_INSIGHT_TOKEN_TITLE",

  FLEET_COMPLETE_ACCOUNT_NAME_INSTRUCTION_1_TITLE = "ONBOARDING.TELEMATICS.FLEET_COMPLETE_ACCOUNT_NAME_INSTRUCTION_1_TITLE",
  FLEET_COMPLETE_ACCOUNT_NAME_HINT = "ONBOARDING.TELEMATICS.FLEET_COMPLETE_ACCOUNT_NAME_HINT",
  FLEET_COMPLETE_ACCOUNT_NAME_FIELD_TITLE = "ONBOARDING.TELEMATICS.FLEET_COMPLETE_ACCOUNT_NAME_FIELD_TITLE",

  FLEET_LOCATE_SPIREON_ACCOUNT_NAME_INSTRUCTION_1_TITLE = "ONBOARDING.TELEMATICS.FLEET_LOCATE_SPIREON_ACCOUNT_NAME_INSTRUCTION_1_TITLE",
  FLEET_LOCATE_SPIREON_ACCOUNT_NAME_HINT = "ONBOARDING.TELEMATICS.FLEET_LOCATE_SPIREON_ACCOUNT_NAME_HINT",
  BASE_ACCOUNT_NAME_TITLE = "ONBOARDING.TELEMATICS.BASE_ACCOUNT_NAME_TITLE",
  OMNITRACS_ACCOUNT_NAME_HINT = "ONBOARDING.TELEMATICS.OMNITRACS_ACCOUNT_NAME_HINT",
  XRS_NAME_HINT = "ONBOARDING.TELEMATICS.XRS_NAME_HINT",
  LYTX_ACCOUNT_NAME_HINT = "ONBOARDING.TELEMATICS.LYTX_ACCOUNT_NAME_HINT",

  ATNT_FLEET_COMPLETE_ACCOUNT_NAME_INSTRUCTION_1_TITLE = "ONBOARDING.TELEMATICS.ATNT_FLEET_COMPLETE_ACCOUNT_NAME_INSTRUCTION_1_TITLE",
  ATNT_FLEET_COMPLETE_ACCOUNT_NAME_HINT = "ONBOARDING.TELEMATICS.ATNT_FLEET_COMPLETE_ACCOUNT_NAME_HINT",

  FLEET_UP_ACCOUNT_NAME_INSTRUCTION_1_TITLE = "ONBOARDING.TELEMATICS.FLEET_UP_ACCOUNT_NAME_INSTRUCTION_1_TITLE",
  FLEET_UP_ACCOUNT_NAME_HINT = "ONBOARDING.TELEMATICS.FLEET_UP_ACCOUNT_NAME_HINT",

  REQUEST_INSTRUCTIONS_URL = "ONBOARDING.TELEMATICS.REQUEST_INSTRUCTIONS_URL",

  WEBFLEET_INSTRUCTIONS_URL = "ONBOARDING.TELEMATICS.WEBFLEET_INSTRUCTIONS_URL",
  SAMSARA_INSTRUCTIONS_URL = "ONBOARDING.TELEMATICS.SAMSARA_INSTRUCTIONS_URL",
  VERIZON_INSTRUCTIONS_URL = "ONBOARDING.TELEMATICS.VERIZON_INSTRUCTIONS_URL",
  GEOTAB_INSTRUCTIONS_URL = "ONBOARDING.TELEMATICS.GEOTAB_INSTRUCTIONS_URL",
  MOTIVE_INSTRUCTIONS_URL = "ONBOARDING.TELEMATICS.MOTIVE_INSTRUCTIONS_URL",
  GPS_INSIGHT_INSTRUCTIONS_URL = "ONBOARDING.TELEMATICS.GPS_INSIGHT_INSTRUCTIONS_URL",
  TRIMBLE_INSTRUCTIONS_URL = "ONBOARDING.TELEMATICS.TRIMBLE_INSTRUCTIONS_URL",
  WIALON_INSTRUCTIONS_URL = "ONBOARDING.TELEMATICS.WIALON_INSTRUCTIONS_URL",
  MIXTELEMATICS_INSTRUCTIONS_URL = "ONBOARDING.TELEMATICS.MIXTELEMATICS_INSTRUCTIONS_URL",
  EROAD_INSTRUCTIONS_URL = "ONBOARDING.TELEMATICS.EROAD_INSTRUCTIONS_URL",
  FLEET_COMPLETE_INSTRUCTIONS_URL = "ONBOARDING.TELEMATICS.FLEET_COMPLETE_INSTRUCTIONS_URL",
  GPS_TAB_INSTRUCTIONS_URL = "ONBOARDING.TELEMATICS.GPS_TAB_INSTRUCTIONS_URL",
  LION_EIGHT_INSTRUCTIONS_URL = "ONBOARDING.TELEMATICS.LION_EIGHT_INSTRUCTIONS_URL",
  LYTX_INSTRUCTIONS_URL = "ONBOARDING.TELEMATICS.LYTX_INSTRUCTIONS_URL",
  OMNITRACS_INSTRUCTIONS_URL = "ONBOARDING.TELEMATICS.OMNITRACS_INSTRUCTIONS_URL",
  ISAAC_INSTRUCTIONS_URL = "ONBOARDING.TELEMATICS.ISAAC_INSTRUCTIONS_URL",
  NETRADYNE_INSTRUCTIONS_URL = "ONBOARDING.TELEMATICS.NETRADYNE_INSTRUCTIONS_URL",
  NEXTRAQ_INSTRUCTIONS_URL = "ONBOARDING.TELEMATICS.NEXTRAQ_INSTRUCTIONS_URL",
  ONE_STEP_GPS_INSTRUCTIONS_URL = "ONBOARDING.TELEMATICS.ONE_STEP_GPS_INSTRUCTIONS_URL",
  FLEET_LOCATE_SPIREON_INSTRUCTIONS_URL = "ONBOARDING.TELEMATICS.FLEET_LOCATE_SPIREON_INSTRUCTIONS_URL",
  XRS_INSTRUCTIONS_URL = "ONBOARDING.TELEMATICS.XRS_INSTRUCTIONS_URL",
  ZONAR_SYSTEMS_INSTRUCTIONS_URL = "ONBOARDING.TELEMATICS.ZONAR_SYSTEMS_INSTRUCTIONS_URL",
  ORBCOMM_ELD_INSTRUCTIONS_URL = "ONBOARDING.TELEMATICS.ORBCOMM_ELD_INSTRUCTIONS_URL",
  ORBCOMM_BLUE_TREE_INSTRUCTIONS_URL = "ONBOARDING.TELEMATICS.ORBCOMM_BLUE_TREE_INSTRUCTIONS_URL",
  VISION_TRACK_INSTRUCTIONS_URL = "ONBOARDING.TELEMATICS.VISION_TRACK_INSTRUCTIONS_URL",
  GORILLA_SAFETY_INSTRUCTIONS_URL = "ONBOARDING.TELEMATICS.GORILLA_SAFETY_INSTRUCTIONS_URL",
  WAYLENS_INSTRUCTIONS_URL = "ONBOARDING.TELEMATICS.WAYLENS_INSTRUCTIONS_URL",
  INSIGHT_MOBILE_DATA_INSTRUCTIONS_URL = "ONBOARDING.TELEMATICS.INSIGHT_MOBILE_DATA_INSTRUCTIONS_URL",
  INSIGHT_MOBILE_DATA_INSTRUCTIONS_2_URL = "ONBOARDING.TELEMATICS.INSIGHT_MOBILE_DATA_INSTRUCTIONS_2_URL",
  RHINO_INSTRUCTIONS_URL = "ONBOARDING.TELEMATICS.RHINO_INSTRUCTIONS_URL",
  RHINO_INSTRUCTIONS_2_URL = "ONBOARDING.TELEMATICS.RHINO_INSTRUCTIONS_2_URL",
  MONARCH_GPS_INSTRUCTIONS_URL = "ONBOARDING.TELEMATICS.MONARCH_GPS_INSTRUCTIONS_URL",
  MONARCH_GPS_INSTRUCTIONS_2_URL = "ONBOARDING.TELEMATICS.MONARCH_GPS_INSTRUCTIONS_2_URL",
  TOUCHTEL_GPS_INSTRUCTIONS_URL = "ONBOARDING.TELEMATICS.TOUCHTEL_GPS_INSTRUCTIONS_URL",
  TOUCHTEL_GPS_INSTRUCTIONS_2_URL = "ONBOARDING.TELEMATICS.TOUCHTEL_GPS_INSTRUCTIONS_2_URL",
  TRUCKSPY_INSTRUCTIONS_URL = "ONBOARDING.TELEMATICS.TRUCKSPY_INSTRUCTIONS_URL",
  TRUCKSPY_INSTRUCTIONS_2_URL = "ONBOARDING.TELEMATICS.TRUCKSPY_INSTRUCTIONS_2_URL",
  GPS_COMMANDER_INSTRUCTIONS_URL = "ONBOARDING.TELEMATICS.GPS_COMMANDER_INSTRUCTIONS_URL",
  ATNT_FLEET_COMPLETE_INSTRUCTIONS_URL = "ONBOARDING.TELEMATICS.ATNT_FLEET_COMPLETE_INSTRUCTIONS_URL",
  FLEET_UP_INSTRUCTIONS_URL = "ONBOARDING.TELEMATICS.FLEET_UP_INSTRUCTIONS_URL",
  ACTSOFT_ENCORE_INSTRUCTIONS_URL = "ONBOARDING.TELEMATICS.ACTSOFT_ENCORE_INSTRUCTIONS_URL",
  ADVANTAGE_ASSET_TRACKING_INSTRUCTIONS_URL = "ONBOARDING.TELEMATICS.ADVANTAGE_ASSET_TRACKING_INSTRUCTIONS_URL",
  ARGOS_CONNECTED_SOLUTIONS_INSTRUCTIONS_URL = "ONBOARDING.TELEMATICS.ARGOS_CONNECTED_SOLUTIONS_INSTRUCTIONS_URL",
  ARI_FLEET_INSTRUCTIONS_URL = "ONBOARDING.TELEMATICS.ARI_FLEET_INSTRUCTIONS_URL",
  ASSURED_TELEMATICS_INSTRUCTIONS_URL = "ONBOARDING.TELEMATICS.ASSURED_TELEMATICS_INSTRUCTIONS_URL",
  ATNT_FLEET_INSTRUCTIONS_URL = "ONBOARDING.TELEMATICS.ATNT_FLEET_INSTRUCTIONS_URL",
  ATTRIX_INSTRUCTIONS_URL = "ONBOARDING.TELEMATICS.ATTRIX_INSTRUCTIONS_URL",
  BADGER_FLEET_SOLUTIONS_INSTRUCTIONS_URL = "ONBOARDING.TELEMATICS.BADGER_FLEET_SOLUTIONS_INSTRUCTIONS_URL",
  BAR_CODING_CANADA_INSTRUCTIONS_URL = "ONBOARDING.TELEMATICS.BAR_CODING_CANADA_INSTRUCTIONS_URL",
  BLUEARROW_INSTRUCTIONS_URL = "ONBOARDING.TELEMATICS.BLUEARROW_INSTRUCTIONS_URL",
  CARRIERHQ_INSTRUCTIONS_URL = "ONBOARDING.TELEMATICS.CARRIERHQ_INSTRUCTIONS_URL",
  ENVUE_TELEMATICS_INSTRUCTIONS_URL = "ONBOARDING.TELEMATICS.ENVUE_TELEMATICS_INSTRUCTIONS_URL",
  FLEET_NAV_SYSTEMS_INSTRUCTIONS_URL = "ONBOARDING.TELEMATICS.FLEET_NAV_SYSTEMS_INSTRUCTIONS_URL",
  FLEET_PROFIT_CENTER_INSTRUCTIONS_URL = "ONBOARDING.TELEMATICS.FLEET_PROFIT_CENTER_INSTRUCTIONS_URL",
  FLEETBOSS_GPS_INSTRUCTIONS_URL = "ONBOARDING.TELEMATICS.FLEETBOSS_GPS_INSTRUCTIONS_URL",
  FLEETHOSTER_INSTRUCTIONS_URL = "ONBOARDING.TELEMATICS.FLEETHOSTER_INSTRUCTIONS_URL",
  FLEETISTICS_INSTRUCTIONS_URL = "ONBOARDING.TELEMATICS.FLEETISTICS_INSTRUCTIONS_URL",
  FLEETLOCATE_GEOTAB_INSTRUCTIONS_URL = "ONBOARDING.TELEMATICS.FLEETLOCATE_GEOTAB_INSTRUCTIONS_URL",
  FLEETMASTER_INSTRUCTIONS_URL = "ONBOARDING.TELEMATICS.FLEETMASTER_INSTRUCTIONS_URL",
  FLEXPORT_INSTRUCTIONS_URL = "ONBOARDING.TELEMATICS.FLEXPORT_INSTRUCTIONS_URL",
  GEOFORCE_INSTRUCTIONS_URL = "ONBOARDING.TELEMATICS.GEOFORCE_INSTRUCTIONS_URL",
  GLOSTONE_INSTRUCTIONS_URL = "ONBOARDING.TELEMATICS.GLOSTONE_INSTRUCTIONS_URL",
  GOFLEET_INSTRUCTIONS_URL = "ONBOARDING.TELEMATICS.GOFLEET_INSTRUCTIONS_URL",
  GOGPS_INSTRUCTIONS_URL = "ONBOARDING.TELEMATICS.GOGPS_INSTRUCTIONS_URL",
  GPS_FLEET_FINDER_INSTRUCTIONS_URL = "ONBOARDING.TELEMATICS.GPS_FLEET_FINDER_INSTRUCTIONS_URL",
  GPS_SOLUTIONS_INSTRUCTIONS_URL = "ONBOARDING.TELEMATICS.GPS_SOLUTIONS_INSTRUCTIONS_URL",
  GPS_TRACKING_CANADA_INSTRUCTIONS_URL = "ONBOARDING.TELEMATICS.GPS_TRACKING_CANADA_INSTRUCTIONS_URL",
  GRAYBOX_SOLUTIONS_INSTRUCTIONS_URL = "ONBOARDING.TELEMATICS.GRAYBOX_SOLUTIONS_INSTRUCTIONS_URL",
  GRIDLINE_INSTRUCTIONS_URL = "ONBOARDING.TELEMATICS.GRIDLINE_INSTRUCTIONS_URL",
  HIGHPOINT_GPS_INSTRUCTIONS_URL = "ONBOARDING.TELEMATICS.HIGHPOINT_GPS_INSTRUCTIONS_URL",
  IOTAB_INSTRUCTIONS_URL = "ONBOARDING.TELEMATICS.IOTAB_INSTRUCTIONS_URL",
  LYNX_INSTRUCTIONS_URL = "ONBOARDING.TELEMATICS.LYNX_INSTRUCTIONS_URL",
  ONTRAK_SOLUTIONS_INSTRUCTIONS_URL = "ONBOARDING.TELEMATICS.ONTRAK_SOLUTIONS_INSTRUCTIONS_URL",
  PENSKE_INSTRUCTIONS_URL = "ONBOARDING.TELEMATICS.PENSKE_INSTRUCTIONS_URL",
  PREPASS_ELD_INSTRUCTIONS_URL = "ONBOARDING.TELEMATICS.PREPASS_ELD_INSTRUCTIONS_URL",
  QUALITY_GPS_INSTRUCTIONS_URL = "ONBOARDING.TELEMATICS.QUALITY_GPS_INSTRUCTIONS_URL",
  RMJ_TECHNOLOGIES_INSTRUCTIONS_URL = "ONBOARDING.TELEMATICS.RMJ_TECHNOLOGIES_INSTRUCTIONS_URL",
  RUSH_ENTERPRISES_INSTRUCTIONS_URL = "ONBOARDING.TELEMATICS.RUSH_ENTERPRISES_INSTRUCTIONS_URL",
  RYDER_INSTRUCTIONS_URL = "ONBOARDING.TELEMATICS.RYDER_INSTRUCTIONS_URL",
  SAFETY_VISION_INSTRUCTIONS_URL = "ONBOARDING.TELEMATICS.SAFETY_VISION_INSTRUCTIONS_URL",
  SHELL_TELEMATICS_INSTRUCTIONS_URL = "ONBOARDING.TELEMATICS.SHELL_TELEMATICS_INSTRUCTIONS_URL",
  SURECAM_INSTRUCTIONS_URL = "ONBOARDING.TELEMATICS.SURECAM_INSTRUCTIONS_URL",
  T_MOBILE_INSTRUCTIONS_URL = "ONBOARDING.TELEMATICS.T_MOBILE_INSTRUCTIONS_URL",
  TRANSFLO_INSTRUCTIONS_URL = "ONBOARDING.TELEMATICS.TRANSFLO_INSTRUCTIONS_URL",
  TRAXXISGPS_INSTRUCTIONS_URL = "ONBOARDING.TELEMATICS.TRAXXISGPS_INSTRUCTIONS_URL",
  TRUPATH_SYSTEMS_INSTRUCTIONS_URL = "ONBOARDING.TELEMATICS.TRUPATH_SYSTEMS_INSTRUCTIONS_URL",
  VERTRAX_INSTRUCTIONS_URL = "ONBOARDING.TELEMATICS.VERTRAX_INSTRUCTIONS_URL",
  ZENDUIT_INSTRUCTIONS_URL = "ONBOARDING.TELEMATICS.ZENDUIT_INSTRUCTIONS_URL",
  HOLMAN_INSTRUCTIONS_URL = "ONBOARDING.TELEMATICS.HOLMAN_INSTRUCTIONS_URL",
  ENTERPRISE_FLEET_MANAGEMENT_INSTRUCTIONS_URL = "ONBOARDING.TELEMATICS.ENTERPRISE_FLEET_MANAGEMENT_INSTRUCTIONS_URL",
  LINXUP_INSTRUCTIONS_URL = "ONBOARDING.TELEMATICS.LINXUP_INSTRUCTIONS_URL",
  FLEETSHARP_INSTRUCTIONS_URL = "ONBOARDING.TELEMATICS.FLEETSHARP_INSTRUCTIONS_URL",
  ELD_RIDER_PRO_INSTRUCTIONS_URL = "ONBOARDING.TELEMATICS.ELD_RIDER_PRO_INSTRUCTIONS_URL",
  SHELD_INSTRUCTIONS_URL = "ONBOARDING.TELEMATICS.SHELD_INSTRUCTIONS_URL",
  XELD_INSTRUCTIONS_URL = "ONBOARDING.TELEMATICS.XELD_INSTRUCTIONS_URL",
  ZELD_INSTRUCTIONS_URL = "ONBOARDING.TELEMATICS.ZELD_INSTRUCTIONS_URL",

  ACTSOFT_ENCORE_DATABASE_HINT = "ONBOARDING.TELEMATICS.ACTSOFT_ENCORE_DATABASE_HINT",
  ADVANTAGE_ASSET_TRACKING_DATABASE_HINT = "ONBOARDING.TELEMATICS.ADVANTAGE_ASSET_TRACKING_DATABASE_HINT",
  ARGOS_CONNECTED_SOLUTIONS_DATABASE_HINT = "ONBOARDING.TELEMATICS.ARGOS_CONNECTED_SOLUTIONS_DATABASE_HINT",
  ARI_FLEET_DATABASE_HINT = "ONBOARDING.TELEMATICS.ARI_FLEET_DATABASE_HINT",
  ASSURED_TELEMATICS_DATABASE_HINT = "ONBOARDING.TELEMATICS.ASSURED_TELEMATICS_DATABASE_HINT",
  ATNT_FLEET_DATABASE_HINT = "ONBOARDING.TELEMATICS.ATNT_FLEET_DATABASE_HINT",
  ATTRIX_DATABASE_HINT = "ONBOARDING.TELEMATICS.ATTRIX_DATABASE_HINT",
  BADGER_FLEET_SOLUTIONS_DATABASE_HINT = "ONBOARDING.TELEMATICS.BADGER_FLEET_SOLUTIONS_DATABASE_HINT",
  BAR_CODING_CANADA_DATABASE_HINT = "ONBOARDING.TELEMATICS.BAR_CODING_CANADA_DATABASE_HINT",
  BLUEARROW_DATABASE_HINT = "ONBOARDING.TELEMATICS.BLUEARROW_DATABASE_HINT",
  CARRIERHQ_DATABASE_HINT = "ONBOARDING.TELEMATICS.CARRIERHQ_DATABASE_HINT",
  ENVUE_TELEMATICS_DATABASE_HINT = "ONBOARDING.TELEMATICS.ENVUE_TELEMATICS_DATABASE_HINT",
  FLEET_NAV_SYSTEMS_DATABASE_HINT = "ONBOARDING.TELEMATICS.FLEET_NAV_SYSTEMS_DATABASE_HINT",
  FLEET_PROFIT_CENTER_DATABASE_HINT = "ONBOARDING.TELEMATICS.FLEET_PROFIT_CENTER_DATABASE_HINT",
  FLEETBOSS_GPS_DATABASE_HINT = "ONBOARDING.TELEMATICS.FLEETBOSS_GPS_DATABASE_HINT",
  FLEETHOSTER_DATABASE_HINT = "ONBOARDING.TELEMATICS.FLEETHOSTER_DATABASE_HINT",
  FLEETISTICS_DATABASE_HINT = "ONBOARDING.TELEMATICS.FLEETISTICS_DATABASE_HINT",
  FLEETLOCATE_GEOTAB_DATABASE_HINT = "ONBOARDING.TELEMATICS.FLEETLOCATE_GEOTAB_DATABASE_HINT",
  FLEETMASTER_DATABASE_HINT = "ONBOARDING.TELEMATICS.FLEETMASTER_DATABASE_HINT",
  FLEXPORT_DATABASE_HINT = "ONBOARDING.TELEMATICS.FLEXPORT_DATABASE_HINT",
  GEOFORCE_DATABASE_HINT = "ONBOARDING.TELEMATICS.GEOFORCE_DATABASE_HINT",
  GLOSTONE_DATABASE_HINT = "ONBOARDING.TELEMATICS.GLOSTONE_DATABASE_HINT",
  GOFLEET_DATABASE_HINT = "ONBOARDING.TELEMATICS.GOFLEET_DATABASE_HINT",
  GOGPS_DATABASE_HINT = "ONBOARDING.TELEMATICS.GOGPS_DATABASE_HINT",
  GPS_FLEET_FINDER_DATABASE_HINT = "ONBOARDING.TELEMATICS.GPS_FLEET_FINDER_DATABASE_HINT",
  GPS_SOLUTIONS_DATABASE_HINT = "ONBOARDING.TELEMATICS.GPS_SOLUTIONS_DATABASE_HINT",
  GPS_TRACKING_CANADA_DATABASE_HINT = "ONBOARDING.TELEMATICS.GPS_TRACKING_CANADA_DATABASE_HINT",
  GRAYBOX_SOLUTIONS_DATABASE_HINT = "ONBOARDING.TELEMATICS.GRAYBOX_SOLUTIONS_DATABASE_HINT",
  GRIDLINE_DATABASE_HINT = "ONBOARDING.TELEMATICS.GRIDLINE_DATABASE_HINT",
  HIGHPOINT_GPS_DATABASE_HINT = "ONBOARDING.TELEMATICS.HIGHPOINT_GPS_DATABASE_HINT",
  IOTAB_DATABASE_HINT = "ONBOARDING.TELEMATICS.IOTAB_DATABASE_HINT",
  LYNX_DATABASE_HINT = "ONBOARDING.TELEMATICS.LYNX_DATABASE_HINT",
  ONTRAK_SOLUTIONS_DATABASE_HINT = "ONBOARDING.TELEMATICS.ONTRAK_SOLUTIONS_DATABASE_HINT",
  PENSKE_DATABASE_HINT = "ONBOARDING.TELEMATICS.PENSKE_DATABASE_HINT",
  PREPASS_ELD_DATABASE_HINT = "ONBOARDING.TELEMATICS.PREPASS_ELD_DATABASE_HINT",
  QUALITY_GPS_DATABASE_HINT = "ONBOARDING.TELEMATICS.QUALITY_GPS_DATABASE_HINT",
  RMJ_TECHNOLOGIES_DATABASE_HINT = "ONBOARDING.TELEMATICS.RMJ_TECHNOLOGIES_DATABASE_HINT",
  RUSH_ENTERPRISES_DATABASE_HINT = "ONBOARDING.TELEMATICS.RUSH_ENTERPRISES_DATABASE_HINT",
  RYDER_DATABASE_HINT = "ONBOARDING.TELEMATICS.RYDER_DATABASE_HINT",
  SAFETY_VISION_DATABASE_HINT = "ONBOARDING.TELEMATICS.SAFETY_VISION_DATABASE_HINT",
  SHELL_TELEMATICS_DATABASE_HINT = "ONBOARDING.TELEMATICS.SHELL_TELEMATICS_DATABASE_HINT",
  SURECAM_DATABASE_HINT = "ONBOARDING.TELEMATICS.SURECAM_DATABASE_HINT",
  T_MOBILE_DATABASE_HINT = "ONBOARDING.TELEMATICS.T_MOBILE_DATABASE_HINT",
  TRANSFLO_DATABASE_HINT = "ONBOARDING.TELEMATICS.TRANSFLO_DATABASE_HINT",
  TRAXXISGPS_DATABASE_HINT = "ONBOARDING.TELEMATICS.TRAXXISGPS_DATABASE_HINT",
  TRUPATH_SYSTEMS_DATABASE_HINT = "ONBOARDING.TELEMATICS.TRUPATH_SYSTEMS_DATABASE_HINT",
  VERTRAX_DATABASE_HINT = "ONBOARDING.TELEMATICS.VERTRAX_DATABASE_HINT",
  ZENDUIT_DATABASE_HINT = "ONBOARDING.TELEMATICS.ZENDUIT_DATABASE_HINT",

  CHECK_CONNECTION_ERROR_TITLE = "ONBOARDING.TELEMATICS.CHECK_CONNECTION_ERROR_TITLE",
  CHECK_CONNECTION_ERROR_SUBTITLE = "ONBOARDING.TELEMATICS.CHECK_CONNECTION_ERROR_SUBTITLE",
  CHECK_CONNECTION_ERROR_BAD_CREDS_SUBTITLE = "ONBOARDING.TELEMATICS.CHECK_CONNECTION_ERROR_BAD_CREDS_SUBTITLE",
  CHECK_CONNECTION_ERROR_REQEST_ERROR_SUBTITLE = "ONBOARDING.TELEMATICS.CHECK_CONNECTION_ERROR_REQEST_ERROR_SUBTITLE",

  OAUTH_CONNECTED_TITLE = "ONBOARDING.TELEMATICS.OAUTH_CONNECTED_TITLE",
  OAUTH_DESCRIPTION = "ONBOARDING.TELEMATICS.OAUTH_DESCRIPTION",
  OAUTH_REVOKE_TITLE = "ONBOARDING.TELEMATICS.OAUTH_REVOKE_TITLE",
  OAUTH_OTHER_METHOD_TITLE = "ONBOARDING.TELEMATICS.OAUTH_OTHER_METHOD_TITLE",
  BASE_OAUTH_BUTTON_TITLE = "ONBOARDING.TELEMATICS.BASE_OAUTH_BUTTON_TITLE",
  OAUTH_ERROR_TITLE = "ONBOARDING.TELEMATICS.OAUTH_ERROR_TITLE",

  SAMSARA_OAUTH_BUTTON_TITLE = "ONBOARDING.TELEMATICS.SAMSARA_OAUTH_BUTTON_TITLE",
  MOTIVE_OAUTH_BUTTON_TITLE = "ONBOARDING.TELEMATICS.MOTIVE_OAUTH_BUTTON_TITLE",

  TSP_ADD_DESCRIPTION = "ONBOARDING.TELEMATICS.TSP_ADD_DESCRIPTION",
  TSP_ADD_BUTTON_TITLE = "ONBOARDING.TELEMATICS.TSP_ADD_BUTTON_TITLE",

  REQUEST_TSP_TITLE = "ONBOARDING.TELEMATICS.REQUEST_TSP_TITLE",
  REQUEST_TSP_SUBTITLE = "ONBOARDING.TELEMATICS.REQUEST_TSP_SUBTITLE",
  REQUEST_TSP_USERNAME_TITLE = "ONBOARDING.TELEMATICS.REQUEST_TSP_USERNAME_TITLE",
  REQUEST_TSP_PASSWORD_TITLE = "ONBOARDING.TELEMATICS.REQUEST_TSP_PASSWORD_TITLE",

  TSP_METHOD_SELECT_BASIC_TITLE = "ONBOARDING.TELEMATICS.TSP_METHOD_SELECT_BASIC_TITLE",
}
