import {
  API_SERVICE_INJECTION_KEY,
  I18N_SERVICE_INJECTION_KEY,
} from "@/constants";
import { LOCALES } from "~/types";
import { COMMON_MESSAGES } from "./common-messages";
import { APP_MESSAGES } from "./app-messages";
import { ERRORS_MESSAGES } from "./errors-messages";
import { injector } from "@/services";

export default async () => {
  const i18n = injector.injectFromRoot(I18N_SERVICE_INJECTION_KEY);
  if (i18n) {
    i18n.global.mergeLocaleMessage(LOCALES.EN_US, {
      COMMON: COMMON_MESSAGES,
    });
    i18n.global.mergeLocaleMessage(LOCALES.EN_US, {
      APP: APP_MESSAGES,
    });
    i18n.global.mergeLocaleMessage(LOCALES.EN_US, {
      ERRORS: ERRORS_MESSAGES,
    });
  }
};
