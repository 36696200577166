import { CONFIG_RENDER_SERVICE_INJECTION_KEY } from "@/constants";
import { injector } from "@/services";
import {
  CONGRATS_RENDER_FEATURE_KEY,
  CONGRATS_RENDER_FEATURE_PATH,
  WELCOME_BLOCK_RENDER_FEATURE_KEY,
  WELCOME_BLOCK_RENDER_FEATURE_PATH,
  COMPANY_INFO_RENDER_FEATURE_KEY,
  COMPANY_INFO_RENDER_FEATURE_PATH,
} from "../../constants";
import { getOnboardingRenderModel } from "../api";
import {
  LOGO_BLOCK_RENDER_FEATURE_KEY,
  LOGO_BLOCK_RENDER_FEATURE_PATH,
  SUPPORT_INFO_RENDER_FEATURE_KEY,
  SUPPORT_INFO_RENDER_FEATURE_PATH,
} from "@/modules/app";
import { get } from "draivn-ui-domain";

export default async () => {
  const configRenderService = injector.injectFromRoot(
    CONFIG_RENDER_SERVICE_INJECTION_KEY
  );
  if (configRenderService) {
    const infoConfig = await getOnboardingRenderModel();
    if (infoConfig) {
      const config = { info: infoConfig };
      configRenderService.addToConfig(
        LOGO_BLOCK_RENDER_FEATURE_KEY,
        get(config, `${LOGO_BLOCK_RENDER_FEATURE_PATH}.config`, {})
      );
      configRenderService.addToConfig(
        SUPPORT_INFO_RENDER_FEATURE_KEY,
        get(config, `${SUPPORT_INFO_RENDER_FEATURE_PATH}.config`, {})
      );
    }
    configRenderService.register(
      WELCOME_BLOCK_RENDER_FEATURE_KEY,
      WELCOME_BLOCK_RENDER_FEATURE_PATH
    );
    configRenderService.addToConfig(WELCOME_BLOCK_RENDER_FEATURE_KEY, {
      title: "ONBOARDING.WELCOME.TITLE",
      description: "ONBOARDING.WELCOME.SUBTITLE",
      legal_links: [
        {
          title: "ONBOARDING.COMMON.TERM_OF_USE_TITLE",
          url: "https://draivn.com/term-of-use",
        },
        {
          title: "ONBOARDING.COMMON.PRIVACY_POLICY_TITLE",
          url: "https://draivn.com/privacy",
        },
      ],
    });
    configRenderService.register(
      CONGRATS_RENDER_FEATURE_KEY,
      CONGRATS_RENDER_FEATURE_PATH
    );
    configRenderService.addToConfig(CONGRATS_RENDER_FEATURE_KEY, {
      title: "ONBOARDING.COMPLETE.TITLE",
      description: "ONBOARDING.COMPLETE.SUBTITLE",
    });
    configRenderService.register(
      COMPANY_INFO_RENDER_FEATURE_KEY,
      COMPANY_INFO_RENDER_FEATURE_PATH
    );
    configRenderService.addToConfig(COMPANY_INFO_RENDER_FEATURE_KEY, {
      needDot: true,
      needFein: true,
    });
  }
};
