import { injector } from "@/services";
import { type ErrorService, EErrorType } from "draivn-ui-domain";
import { ECommonMessages } from "../../../types";
import { DialogPlugin } from "draivn-ui-kit";
import ConfirmDialog from "../../../components/common/ConfirmDialog.vue";
import { computed } from "vue";
import { I18N_SERVICE_INJECTION_KEY } from "@/constants";
import { useSystemStore } from "../../store";
import { storeToRefs } from "pinia";

export const serverErrorDisplayHandlerInit = (errorService: ErrorService) => {
  const i18n = injector.injectFromRoot(I18N_SERVICE_INJECTION_KEY);
  const serverErrorDisplayHandler = () => {
    const { t } = i18n.global;
    errorService.blockDisplay();
    const $systemStore = useSystemStore();
    $systemStore.setHealthStatus(false);
    const { healthStatus } = storeToRefs($systemStore);
    DialogPlugin.create({
      component: ConfirmDialog,
      componentProps: {
        title: t(ECommonMessages.ERROR_SERVER_TITLE),
        icon: "traffic-cone",
        iconColor: "r500",
        iconBgColor: "r50",
        message: t(ECommonMessages.ERROR_SERVER_MESSAGE),
        isShow: computed(() => !healthStatus.value),
        cancelBtn: {
          loading: true,
          label: t(ECommonMessages.ERROR_SERVER_BUTTON),
          variant: "secondary",
        },
      },
    }).onDismiss(() => {
      errorService.unblockDisplay();
    });
  };

  errorService.registerErrorDisplay(EErrorType.SERVER, {
    display: serverErrorDisplayHandler,
  });
};
