import type { App } from "vue";
import { ConfigRenderService } from "./config-render-service";
import { CONFIG_RENDER_SERVICE_INJECTION_KEY } from "@/constants";
import { injector } from "@/services/injector";

export const configRenderService = new ConfigRenderService();

export default async (app: App) => {
  await configRenderService.build();
  injector.provide(CONFIG_RENDER_SERVICE_INJECTION_KEY, configRenderService);
  app.provide(CONFIG_RENDER_SERVICE_INJECTION_KEY, configRenderService);
  return configRenderService;
};
