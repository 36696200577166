import { api } from "../../../..";
import { ref } from "vue";
import type { IOverview } from "../../../../../types";
export const useOverviewFeature = () => {
  const overview = ref<IOverview | null>(null);

  const initOverview = async () => {
    const currentOverview = await api.getOverview();
    overview.value = currentOverview;
    return overview.value;
  };

  return {
    overview,
    initOverview,
  };
};
