import type {
  PiniaPluginContext,
  Store,
  StateTree,
  SubscriptionCallbackMutation,
} from "pinia";
import { debounce } from "draivn-ui-domain";
import type { IStoragePiniaPluginConfig } from "~/types";

function getDefaultConfig(key: string): IStoragePiniaPluginConfig {
  return {
    key,
    serialize: (value) => value,
    parse: (value) => value,
    storage: window.localStorage,
    debounce: 0,
  };
}

function normalizeConfig(
  defaultConfig: IStoragePiniaPluginConfig,
  config: Partial<IStoragePiniaPluginConfig>
): IStoragePiniaPluginConfig {
  return { ...defaultConfig, ...config };
}

function restore(
  store: Store,
  { storage, key, parse }: IStoragePiniaPluginConfig
) {
  try {
    const data = storage.getItem(key);
    if (data) {
      store.$patch(parse(data));
    }
  } catch (e) {
    console.error(e);
  }
}

function save(
  state: StateTree,
  { serialize, key, storage }: IStoragePiniaPluginConfig
) {
  try {
    storage.setItem(key, serialize(state));
  } catch (e) {
    console.error(e);
  }
}

function createStorageWrapper(
  store: Store,
  config: IStoragePiniaPluginConfig
): void {
  restore(store, config);

  const debouncedSave = config.debounce
    ? debounce(save, config.debounce)
    : save;

  store.$subscribe(
    (mutation: SubscriptionCallbackMutation<StateTree>, state: StateTree) => {
      debouncedSave(state, config);
    },
    { detached: true }
  );
}

export default ({ options, store }: PiniaPluginContext) => {
  if (options.persist) {
    const defaultConfig: IStoragePiniaPluginConfig = getDefaultConfig(
      store.$id
    );
    let configs: IStoragePiniaPluginConfig[];

    if (Array.isArray(options.persist)) {
      configs = options.persist.map((config) =>
        normalizeConfig(defaultConfig, config)
      );
    } else if (typeof options.persist === "object") {
      configs = [normalizeConfig(defaultConfig, options.persist)];
    } else {
      configs = [
        {
          ...defaultConfig,
          ...{
            serialize: JSON.stringify,
            parse: JSON.parse,
          },
        },
      ];
    }

    configs.forEach((config) => createStorageWrapper(store, config));
  }
};
