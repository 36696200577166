import { storeToRefs } from "pinia";
import { useHelpCenterStore } from "../services/store";
import { computed } from "vue";
import type { IMenuItem, IMenuItemCollection, IBreadcrumbItem } from "../types";
import { get } from "draivn-ui-domain";

export const useMenuItemsFeature = () => {
  const $helpCenterStore = useHelpCenterStore();

  const { menu, article } = storeToRefs($helpCenterStore);

  const getMenuCollection = (
    menu: IMenuItem,
    link?: string
  ): IMenuItemCollection => {
    const menuItem: IMenuItemCollection = {
      index: 0,
      id: menu.id,
      parentId: menu.parentId,
      icon: menu.icon,
      title: menu.title,
      items: menu.items,
      collection: menu.items.length ? {} : null,
      link: `${link ? link + "/" : ""}${menu.id}`,
    };
    if (menu.items.length) {
      menuItem.collection = menu.items.reduce((result, item, index) => {
        const id = item.id;
        result[id] = getMenuCollection(item, menuItem.link);
        result[id].index = index;
        return result;
      }, {} as Record<string, IMenuItemCollection>);
    }
    return menuItem;
  };

  const menuCollection = computed<IMenuItemCollection | null>(() => {
    let collection = null;
    if (menu.value) {
      collection = getMenuCollection(menu.value);
    }
    return collection;
  });

  const getMenuItemsByPath = (path: string): IMenuItemCollection[] => {
    let breadcrumbs: IMenuItemCollection[] = [];
    if (menuCollection.value) {
      const pathParts = path.split("/").filter(Boolean);
      pathParts.splice(0, 1, "");
      const pathPartsWithoutRoot = pathParts.slice(1);
      breadcrumbs = pathParts.reduceRight((result, part) => {
        let menuItem: IMenuItemCollection | null = null;
        if (part) {
          menuItem = menuCollection.value
            ? get(
                menuCollection.value,
                `collection.${pathPartsWithoutRoot.join(".collection.")}`
              )
            : null;
        } else {
          menuItem = menuCollection.value;
        }
        pathPartsWithoutRoot.pop();
        if (menuItem) {
          result.unshift(menuItem);
        }
        return result;
      }, breadcrumbs);
    }
    return breadcrumbs;
  };

  const breadcrubsMenuItems = computed<IMenuItemCollection[]>(() => {
    let menuCollectionItems: IMenuItemCollection[] = [];
    if (menu.value && article.value) {
      menuCollectionItems = getMenuItemsByPath(article.value.link);
    }
    return menuCollectionItems;
  });

  const breadcrubsItems = computed<IBreadcrumbItem[]>(() => {
    let breadcrubsItems: IBreadcrumbItem[] = [];
    if (menu.value && article.value) {
      breadcrubsItems = getMenuItemsByPath(article.value.link).map((item) => {
        return {
          name: item.title,
        };
      });
    }
    return breadcrubsItems;
  });

  return {
    menuCollection,
    breadcrubsMenuItems,
    breadcrubsItems,
  };
};
