import { createRouter, createWebHistory } from "vue-router";
import type { App } from "vue";
import { ROUTER_SERVICE_INJECTION_KEY } from "@/constants";
import { injector } from "@/services/injector";

export const router = createRouter({
  routes: [],
  history: createWebHistory(process.env.BASE_URL),
});

export default (app: App) => {
  app.use(router);
  injector.provide(ROUTER_SERVICE_INJECTION_KEY, router);
  app.provide(ROUTER_SERVICE_INJECTION_KEY, router);
  return router;
};
