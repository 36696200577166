import {
  alreadyExistDisplayHandlerInit,
  authErrorDisplayHandlerInit,
  componentLoadDisplayHandlerInit,
  defaultDisplayHandlerInit,
  disconnectDisplayHandlerInit,
  serverErrorDisplayHandlerInit,
  validationtDisplayHandlerInit,
  localNetworkDisplayHandlerInit,
} from "./handlers";
import { ERROR_SERVICE_INJECTION_KEY } from "@/constants";
import { injector } from "@/services";

export default () => {
  const errorService = injector.injectFromRoot(ERROR_SERVICE_INJECTION_KEY);
  if (errorService) {
    defaultDisplayHandlerInit(errorService);
    disconnectDisplayHandlerInit(errorService);
    localNetworkDisplayHandlerInit(errorService);
    componentLoadDisplayHandlerInit(errorService);
    authErrorDisplayHandlerInit(errorService);
    serverErrorDisplayHandlerInit(errorService);
    validationtDisplayHandlerInit(errorService);
    alreadyExistDisplayHandlerInit(errorService);
  }
};
