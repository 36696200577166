import { ETelematicsMethodType, ITelematicsStepModel } from "../types";

export const TELEMATICS_EMPTY_MODEL: ITelematicsStepModel = {
  url: "",
  name: "",
  platform_type: "",
  channel_id: "",
  sp_id: "",
  method: ETelematicsMethodType.PERMANENT_API,
  credentials: [],
};
