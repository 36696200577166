import { useSupportFeature } from "./features/support";
import { useOverviewFeature } from "./features/overview";
import { useMenuFeature } from "./features/menu";
import { useArticleFeature } from "./features/article";

export const helpCenterStoreInstall = () => {
  const supportFeature = useSupportFeature();
  const overviewFeature = useOverviewFeature();
  const menuFeature = useMenuFeature();
  const articleFeature = useArticleFeature();

  return {
    ...supportFeature,
    ...overviewFeature,
    ...menuFeature,
    ...articleFeature,
  };
};
