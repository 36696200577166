import { API_SERVICE_INJECTION_KEY } from "@/constants";
import { HttpService } from "draivn-ui-domain";
import { errorService } from "../error";
import type { App } from "vue";
import { injector } from "@/services/injector";

const baseURL = process.env.VUE_APP_BASE_URL;
const headers: Record<string, string> = JSON.parse(
  process.env.VUE_APP_BASE_HEADERS ?? "{}"
);

export const apiService = new HttpService({
  baseURL,
  headers,
  errorService,
  retryConfig: {
    retries: 3,
  },
});

export default (app: App) => {
  injector.provide(API_SERVICE_INJECTION_KEY, apiService);
  app.provide(API_SERVICE_INJECTION_KEY, apiService);

  return apiService;
};
