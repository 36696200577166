import { injector } from "@/services";
import {
  APP_LAYOUT_SERVICE_INJECTION_TOKEN,
  ELayoutRegisteredItems,
} from "@/modules/app";
import SidebarComponent from "../../components/SidebarComponent.vue";
export default () => {
  const layoutService = injector.injectFromRoot(
    APP_LAYOUT_SERVICE_INJECTION_TOKEN
  );
  layoutService.registerItem(
    ELayoutRegisteredItems.RIGHT_SIDEBAR,
    SidebarComponent
  );
};
