import { EVENT_BUS_SERVICE_INJECTION_KEY } from "@/constants";
import mitt from "mitt";
import type { App } from "vue";
import { injector } from "@/services/injector";
import { TEventsModel } from "~/types";

export const eventBus = mitt<TEventsModel>();

export default (app: App) => {
  injector.provide(EVENT_BUS_SERVICE_INJECTION_KEY, eventBus);
  app.provide(EVENT_BUS_SERVICE_INJECTION_KEY, eventBus);
  return eventBus;
};
