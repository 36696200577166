import { createApp, type App } from "vue";
import "./assets/css/app.scss";
import AppRootComponent from "./App.vue";
import servicesInstall from "./services";
import modulesInstall from "./modules";

const app: App<Element> = createApp(AppRootComponent);

servicesInstall(app)
  .then(modulesInstall)
  .then(() => {
    app.mount("#app");
  })
  .catch((error) => {
    console.error(error);
  });
