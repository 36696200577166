export enum ECommonMessages {
  SECURITY_LABEL = "ONBOARDING.COMMON.SECURITY_LABEL",
  PRIVACY_POLICY_TITLE = "ONBOARDING.COMMON.PRIVACY_POLICY_TITLE",
  TERM_OF_USE_TITLE = "ONBOARDING.COMMON.TERM_OF_USE_TITLE",
  ASSIGN_TITLE = "ONBOARDING.COMMON.ASSIGN_TITLE",
  ASSIGN_TO_COLLEAGUE_TITLE = "ONBOARDING.COMMON.ASSIGN_TO_COLLEAGUE_TITLE",
  BUTTON_BACK = "ONBOARDING.COMMON.BUTTON_BACK",
  BUTTON_NEXT = "ONBOARDING.COMMON.BUTTON_NEXT",
  BUTTON_COMPLETE = "ONBOARDING.COMMON.BUTTON_COMPLETE",
  NOT_SET = "ONBOARDING.COMMON.NOT_SET",
  CLEAR = "ONBOARDING.COMMON.CLEAR",
  MAX_LENGTH_ERROR = "ONBOARDING.COMMON.MAX_LENGTH_ERROR",
  MIN_LENGTH_ERROR = "ONBOARDING.COMMON.MIN_LENGTH_ERROR",
  REQUIRED_ERROR = "ONBOARDING.COMMON.REQUIRED_ERROR",
  URL_ERROR = "ONBOARDING.COMMON.URL_ERROR",
  INVALID_DATE_ERROR = "ONBOARDING.COMMON.INVALID_DATE_ERROR",
  PHONE_ERROR = "ONBOARDING.COMMON.PHONE_ERROR",
  OPTIONAL = "ONBOARDING.COMMON.OPTIONAL",
  SOC_SECURITY_LABEL = "ONBOARDING.COMMON.SOC_SECURITY_LABEL",
  LAST_UPDATED = "ONBOARDING.COMMON.LAST_UPDATED",
}
