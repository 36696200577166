import { computed } from "vue";
import type { IBreadcrumbItem } from "../types";
import { useRoute, useRouter } from "vue-router";
export const useBreadcrumbsFeature = () => {
  const $route = useRoute();
  const $router = useRouter();
  const breadcrumbValues = computed(() => {
    let vals: IBreadcrumbItem[] = [];
    if ($route.meta?.breadcrumbs) {
      vals = $route.meta.breadcrumbs($route);
    }
    return vals;
  });

  const goBack = () => {
    const breadcrumb: IBreadcrumbItem =
      breadcrumbValues.value[breadcrumbValues.value.length - 2];
    if (breadcrumb && breadcrumb.to) {
      $router.push(breadcrumb.to);
    }
  };

  return {
    breadcrumbValues,
    goBack,
  };
};
