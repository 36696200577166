export enum EInsurerMessagesKeys {
  TITLE = "ONBOARDING.INSURER.TITLE",
  TABNAME = "ONBOARDING.INSURER.TABNAME",
  INSURED_NAME_TITLE = "ONBOARDING.INSURER.INSURED_NAME_TITLE",
  CURRENT_INSURER_NAME_TITLE = "ONBOARDING.INSURER.CURRENT_INSURER_NAME_TITLE",
  POLICY_NUMBER_TITLE = "ONBOARDING.INSURER.POLICY_NUMBER_TITLE",
  POLICY_VALID_UNTIL_TITLE = "ONBOARDING.INSURER.POLICY_VALID_UNTIL_TITLE",
  INSURANCE_INFO_TITLE = "ONBOARDING.INSURER.INSURANCE_INFO_TITLE",
  COMPANY_DETAILS_TITLE = "ONBOARDING.INSURER.COMPANY_DETAILS_TITLE",
  COMPANY_DETAILS_SUBTITLE = "ONBOARDING.INSURER.COMPANY_DETAILS_SUBTITLE",
  FEIN_TITLE = "ONBOARDING.INSURER.FEIN_TITLE",
  DOT_TITLE = "ONBOARDING.INSURER.DOT_TITLE",
  CONTACT_PERSON_PHONE_TITLE = "ONBOARDING.INSURER.CONTACT_PERSON_PHONE_TITLE",
  CURRENT_INSURER_NAME_ERROR = "ONBOARDING.INSURER.CURRENT_INSURER_NAME_ERROR",
  CURRENT_INSURER_NAME_MIN_LENGHT_ERROR = "ONBOARDING.INSURER.CURRENT_INSURER_NAME_MIN_LENGHT_ERROR",
  CURRENT_INSURER_NAME_MAX_LENGHT_ERROR = "ONBOARDING.INSURER.CURRENT_INSURER_NAME_MAX_LENGHT_ERROR",
  DOT_ERROR = "ONBOARDING.INSURER.DOT_ERROR",
  FEIN_ERROR = "ONBOARDING.INSURER.FEIN_ERROR",
  VALIDATORS_DOT_COMMON_MESSAGE = "ONBOARDING.INSURER.VALIDATORS_DOT_COMMON_MESSAGE",
  VALIDATORS_FEIN_COMMON_MESSAGE = "ONBOARDING.INSURER.VALIDATORS_FEIN_COMMON_MESSAGE",
  INSURED_NAME_PLACEHOLDER = "ONBOARDING.INSURER.INSURED_NAME_PLACEHOLDER",
  CONTACT_PERSON_PHONE_PLACEHOLDER = "ONBOARDING.INSURER.CONTACT_PERSON_PHONE_PLACEHOLDER",
  DOT_PLACEHOLDER = "ONBOARDING.INSURER.DOT_PLACEHOLDER",
  FEIN_PLACEHOLDER = "ONBOARDING.INSURER.FEIN_PLACEHOLDER",
  CURRENT_INSURER_NAME_PLACEHOLDER = "ONBOARDING.INSURER.CURRENT_INSURER_NAME_PLACEHOLDER",
}
