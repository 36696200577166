import type { IModuleParams } from "~/types";
import servicesInstall from "./services";

export { useHelpCenterStore } from "./services";

export { default as MainComponent } from "./components/MainComponent.vue";
export { default as SidebarComponent } from "./components/SidebarComponent.vue";

export * from "./types";

export default async (params: IModuleParams | void) => {
  await servicesInstall(params);
};
