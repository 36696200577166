import type { IMapper } from "~/types";
import {
  type IOnboardingModel,
  type IOnboardingModelResponseModel,
} from "../../types";

export const onboardingModelMapper: IMapper<
  IOnboardingModelResponseModel,
  IOnboardingModel
> = (value) => value;
