import { useHealthFeature } from "./features/health";
import { useSystemNotificationsFeature } from "./features/notification";

export const systemStoreInstall = () => {
  const systemNotificationsFeature = useSystemNotificationsFeature();
  const healthFeature = useHealthFeature();

  return {
    ...systemNotificationsFeature,
    ...healthFeature,
  };
};
