import AppLoadingVue from "../components/common/AppLoading.vue";
import { defineAsyncComponent, type AsyncComponentLoader, markRaw } from "vue";
import { EErrorCode } from "draivn-ui-domain";
import { injector } from "@/services";
import { COMPONENT_LOAD_ERROR } from "../constants";
import { ERROR_SERVICE_INJECTION_KEY } from "@/constants";
import { useSystemStore } from "../services/store";

export const getAsyncComponent = (loader: AsyncComponentLoader) => {
  const component = markRaw(
    defineAsyncComponent({
      loader,
      loadingComponent: AppLoadingVue,
      errorComponent: AppLoadingVue,
      async onError(
        error: Error,
        retry: () => void,
        fail: () => void,
        attempts: number
      ) {
        const errorService = injector.injectFromRoot(
          ERROR_SERVICE_INJECTION_KEY
        );

        const $systemStore = useSystemStore();
        const isConnected = $systemStore.connectionStatus;

        if (!isConnected || (error.message.match(/fetch/) && attempts < 3)) {
          setTimeout(() => retry(), 1000);
        } else {
          errorService.showError({
            code: EErrorCode.LOCAL_ERROR,
            reason: COMPONENT_LOAD_ERROR,
            data: [
              {
                message: COMPONENT_LOAD_ERROR,
              },
            ],
          });
          fail();
        }
      },
    })
  );

  return component;
};
