import i18nInstall from "./i18n";
import { IModuleParams } from "~/types";
import router from "./router";

export default async (params: IModuleParams | void) => {
  await router();
  await i18nInstall();

  return {};
};
