import { api } from "../../../..";
import { ref } from "vue";
import type { IMenuItem } from "../../../../../types";
export const useMenuFeature = () => {
  const menu = ref<IMenuItem | null>(null);

  const initMenu = async (group: string) => {
    const currentMenu = await api.getMenu(group);
    menu.value = currentMenu;
    return menu.value;
  };

  const clearMenu = () => {
    menu.value = null;
  };

  return {
    menu,
    initMenu,
    clearMenu,
  };
};
