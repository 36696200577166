import { CONFIG_RENDER_SERVICE_INJECTION_KEY } from "@/constants";
import { injector } from "@/services";
import {
  LOGO_BLOCK_RENDER_FEATURE_KEY,
  LOGO_BLOCK_RENDER_FEATURE_PATH,
  SUPPORT_INFO_RENDER_FEATURE_KEY,
  SUPPORT_INFO_RENDER_FEATURE_PATH,
} from "../../constants";

export default () => {
  const configRenderService = injector.injectFromRoot(
    CONFIG_RENDER_SERVICE_INJECTION_KEY
  );
  if (configRenderService) {
    configRenderService.register(
      LOGO_BLOCK_RENDER_FEATURE_KEY,
      LOGO_BLOCK_RENDER_FEATURE_PATH
    );
    configRenderService.register(
      SUPPORT_INFO_RENDER_FEATURE_KEY,
      SUPPORT_INFO_RENDER_FEATURE_PATH
    );
  }
};
