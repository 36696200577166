import appModuleInstall, { ROUTE_MAIN_NAME } from "./app";
import onboardingModuleInstall from "./onboarding";
import helpCenterModuleInstall from "./help-center";
import postInstallModuleInstall from "./post-install";

export default async () => {
  await appModuleInstall();
  await onboardingModuleInstall({
    routerConfig: {
      mountRouteName: ROUTE_MAIN_NAME,
    },
  });
  await helpCenterModuleInstall({
    apiConfig: {
      baseUrl: "/help",
    },
  });
  await postInstallModuleInstall();
};
