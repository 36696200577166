import { injector } from "@/services";
import {
  EErrorType,
  type ErrorService,
  type IApiError,
} from "draivn-ui-domain";
import { I18N_SERVICE_INJECTION_KEY } from "@/constants";

export const validationtDisplayHandlerInit = (errorService: ErrorService) => {
  const i18n = injector.injectFromRoot(I18N_SERVICE_INJECTION_KEY);
  const validationErrorDisplayHandler = (error: IApiError) => {
    const { t } = i18n.global;
    errorService.blockDisplay();
  };

  errorService.registerErrorDisplay(EErrorType.VALIDATION, {
    display: validationErrorDisplayHandler,
  });
};
