import { EVENT_BUS_SERVICE_INJECTION_KEY } from "@/constants";
import { injector } from "@/services";
import { Component, computed, ref } from "vue";
import { APP_LAYOUT_SERVICE_INJECTION_TOKEN } from "../constants";
import { ELayoutRegisteredItems } from "../types";
import { EEvents } from "~/types";

export const useLayoutBaseFeature = (drawerLeftInitValue = true) => {
  const eventBusService = injector.injectFromRoot(
    EVENT_BUS_SERVICE_INJECTION_KEY
  );

  const layoutService = injector.injectFromRoot(
    APP_LAYOUT_SERVICE_INJECTION_TOKEN
  );

  const drawerLeft = ref<boolean>(drawerLeftInitValue);
  const drawerLeftComponent = computed<Component | undefined>(() => {
    return layoutService.getItem(ELayoutRegisteredItems.LEFT_SIDEBAR);
  });

  const drawerRight = ref<boolean>(false);
  const drawerRightComponent = computed<Component | undefined>(() => {
    return layoutService.getItem(ELayoutRegisteredItems.RIGHT_SIDEBAR);
  });

  eventBusService.on(EEvents.ON_LEFT_SIDEBAR_TOOGLE, (value: boolean) => {
    drawerLeft.value = value;
  });

  eventBusService.on(EEvents.ON_RIGHT_SIDEBAR_TOOGLE, (value: boolean) => {
    drawerRight.value = value;
  });

  return {
    drawerRight,
    drawerLeft,
    drawerLeftComponent,
    drawerRightComponent,
  };
};
