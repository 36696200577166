export enum ESummaryMessagesKeys {
  TITLE = "ONBOARDING.SUMMARY.TITLE",
  TABNAME = "ONBOARDING.SUMMARY.TABNAME",
  INSURANCE_TITLE = "ONBOARDING.SUMMARY.INSURANCE_TITLE",
  COMPANY_TITLE = "ONBOARDING.SUMMARY.COMPANY_TITLE",
  VEHICLE_TITLE = "ONBOARDING.SUMMARY.VEHICLE_TITLE",
  TELEMATICS_TITLE = "ONBOARDING.SUMMARY.TELEMATICS_TITLE",
  SHARING_TITLE = "ONBOARDING.SUMMARY.SHARING_TITLE",
  SHARING_DESCRIPTION = "ONBOARDING.SUMMARY.SHARING_DESCRIPTION",
  AGREE_TITLE = "ONBOARDING.SUMMARY.AGREE_TITLE",
  AGREE_TEXT = "ONBOARDING.SUMMARY.AGREE_TEXT",
  ACKNOWLEDGEMENT_TEXT = "ONBOARDING.SUMMARY.ACKNOWLEDGEMENT_TEXT",
  LICENCE_AGREEMENT_TITLE = "ONBOARDING.SUMMARY.LICENCE_AGREEMENT_TITLE",
  ASSIGNED_TO = "ONBOARDING.SUMMARY.ASSIGNED_TO",
  FILE_UPLOADED = "ONBOARDING.SUMMARY.FILE_UPLOADED",
  FILE_IMPORTED = "ONBOARDING.SUMMARY.FILE_IMPORTED",
  FILES_IMPORTED = "ONBOARDING.SUMMARY.FILES_IMPORTED",
  LOADED_API = "ONBOARDING.SUMMARY.LOADED_API",
  LOADED_MANUALLY = "ONBOARDING.SUMMARY.LOADED_MANUALLY",
  LOADED_SKIPED = "ONBOARDING.SUMMARY.LOADED_SKIPED",
  LOADED_REQUESTED = "ONBOARDING.SUMMARY.LOADED_REQUESTED",
  FIRSTNAME_TITLE = "ONBOARDING.SUMMARY.FIRSTNAME_TITLE",
  FIRSTNAME_PLACEHOLDER = "ONBOARDING.SUMMARY.FIRSTNAME_PLACEHOLDER",
  LASTNAME_TITLE = "ONBOARDING.SUMMARY.LASTNAME_TITLE",
  LASTNAME_PLACEHOLDER = "ONBOARDING.SUMMARY.LASTNAME_PLACEHOLDER",
}
