import { api } from "./../../../../";
import type { ISupportInfo } from "../../../../../types";
import { ref } from "vue";

export const useSupportFeature = () => {
  const supportInfo = ref<ISupportInfo | null>(null);

  const initSupportInfo = async () => {
    const info = await api.getSupportInfo();
    supportInfo.value = info;
    return supportInfo.value;
  };

  return {
    supportInfo,
    initSupportInfo,
  };
};
