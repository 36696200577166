import { useUIKit } from "draivn-ui-kit";
import DemoDialog from "../components/blocks/DemoDialog.vue";
import type { IDemoRequest } from "../types";
import { api } from "../services";

export const useShowDemoDialogFeature = () => {
  const $uiKit = useUIKit();

  const onDemoAsk = async (model: IDemoRequest) => {
    const resp = await api.postDemoRequest(model);
    return resp;
  };

  const showDemoDialog = () => {
    $uiKit
      .dialog({
        component: DemoDialog,
      })
      .onOk((model: IDemoRequest) => {
        onDemoAsk(model);
      });
  };

  return {
    showDemoDialog,
  };
};
