import { defineStore } from "pinia";
import { systemStoreInstall } from "./modules";
import { provide } from "vue";
import { SYSTEM_STORE_INJECTION_KEY } from "../../constants";

export const useSystemStore = defineStore("system", systemStoreInstall);

export default () => {
  provide<typeof useSystemStore>(SYSTEM_STORE_INJECTION_KEY, useSystemStore);

  return {
    useSystemStore,
  };
};
